import React, { useEffect, useState } from "react";
import DropDown from "../DropDown";
import Input from "../Input";
import SelectMapLocation from "../SelectMapLocation";
import GoogleSearchAutoComplete from "../GoogleSearchAutoComplete";
import { useJsApiLoader } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import axiosAPI from "../../utils/axiosAPI";
import { setStoreInfoDetails } from "../../redux/slices/vendorRegisterRedux";

type Props = {
  goNext: () => void;
  goBack: () => void;
};

export default function StoreInfo({ goNext, goBack }: Props) {
  const dispatch = useDispatch();

  // user Data with profile and Auth from Redux Store
  const user = useSelector((state: any) => state.user.userData);

  // Already Filled Inputs Data from Redux Store
  const storeInfoData = useSelector(
    (state: any) => state.vendorRegister.storeInfo
  );

  // State to Check if Any Inputs Got Changed
  const [isAnyInputGotChanged, setIsAnyInputGotChanged] = useState(false);

  // Load Google API JS for Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBnfOnQWvWhEapbyJcCDMQz6-m5AbzQAjg",
    libraries: ["places"],
  });

  // States for Country Dropdown
  const countriesList = ["United Arab Emirates"];
  const [selectedCountry, setSelectedCountry] = useState("");

  const [apiError, setApiError] = useState("");
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  // States for City Dropdown
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");

  // States for Inputs
  const [buildingName, setBuildingName] = useState("");
  const [shopName, setShopName] = useState("");

  // State for Select Location on Google Map
  const [mapPoints, setMapPoints] = useState({
    lat: 25.184672972293185,
    lng: 55.29787416989978,
  });

  // States for Validtion Errors
  const [isCountryNotSelected, setIsCountryNotSelected] = useState(false);
  const [isCityNotSelected, setIsCityNotSelected] = useState(false);
  const [isAddressError, setIsAddressError] = useState(false);
  const [isBuildingNameError, setIsBuildingNameError] = useState(false);
  const [isShopNameError, setIsShopNameError] = useState(false);
  const [isLocationSelectedNotOnMap, setIsLocationSelectedNotOnMap] =
    useState(false);

  // Function for Dropdown to select Country
  const selectCoutry = (country: string) => {
    setSelectedCountry(country);
    setIsCountryNotSelected(false);
    setSelectedCity("");
    setIsAnyInputGotChanged(true);
  };

  // Function for Dropdown to select City
  const selectCity = (city: string) => {
    setSelectedCity(city);
    setIsCityNotSelected(false);
    setIsAnyInputGotChanged(true);
  };

  // Function to update coordinates when user selects location on Google Maps
  const selectLoction = (lat: number, lng: number) => {
    setMapPoints({
      lat: lat,
      lng: lng,
    });
    // Hide Validtion error when user selects location on Google Maps
    setIsLocationSelectedNotOnMap(false);
    setIsAnyInputGotChanged(true);
  };

  // Country wise Cities lists
  const uaeCitiesList = [
    "Dubai",
    "Abu Dhabi",
    "Sharjah",
    "Ajman",
    "Fujairah",
    "Ras Al Khaimah",
    "Umm Al Quwain",
  ];

  const saudiArabiaCitiesList = [
    "Riyadh",
    "Jeddah",
    "Mecca",
    "Medina",
    "Dammam",
    "Tabuk",
    "Buraidah",
    "Abha",
    "Hail",
    "Arar",
    "Najran",
    "Jizan",
    "Al Bahah",
    "Sakakah",
  ];

  // Check for all required fields and go next
  const clickContinue = () => {
    if (
      selectedCountry &&
      selectedCity &&
      address &&
      buildingName &&
      shopName &&
      mapPoints &&
      // Check if Selected location got changed from default position or not
      mapPoints.lat !== 25.184672972293185 &&
      mapPoints.lng !== 55.29787416989978
    ) {
      setIsAddingAddress(true);
      // Check if Nothing Got changed then go next
      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }
      axiosAPI
        .post(
          "/v1/api/user/address/add",
          {
            businessName: shopName,
            address: address,
            city: selectedCity,
            country: 1,
            area: area,
            building: buildingName,
            streetName: address,
            latitude: mapPoints.lat,
            longitude: mapPoints.lng,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .then((res) => {
          dispatch(
            setStoreInfoDetails({
              businessName: shopName,
              address: address,
              city: selectedCity,
              country: selectedCountry,
              area: area,
              building: buildingName,
              streetName: address,
              latitude: mapPoints.lat,
              longitude: mapPoints.lng,
            })
          );
          setIsAddingAddress(false);
          goNext();
        })
        .catch((error) => {
          setIsAddingAddress(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check if country is selected or not
      if (!selectedCountry) {
        setIsCountryNotSelected(true);
      }

      // Check if City is selected or not
      if (!selectedCity) {
        setIsCityNotSelected(true);
      }

      if (!address) {
        setIsAddressError(true);
      }

      // Check if Building Name is Entered or not
      if (!buildingName) {
        setIsBuildingNameError(true);
      }

      // Check if Shop Name is Entered or not
      if (!shopName) {
        setIsShopNameError(true);
      }

      // Check if Selected location got changed from default position or not
      if (
        !mapPoints ||
        mapPoints.lat === 25.184672972293185 ||
        mapPoints.lng === 55.29787416989978
      ) {
        setIsLocationSelectedNotOnMap(true);
      }
    }
  };

  const googleSearchAutoComplete = (address: {
    country: string;
    city: string;
    area: string;
    address: string;
    latitude: number;
    longitude: number;
  }) => {
    setSelectedCountry(address.country);
    setSelectedCity(address.city);
    setAddress(address.address);
    setArea(address.area);
    setMapPoints({
      lat: address.latitude,
      lng: address.longitude,
    });
    setIsAddressError(false);
  };

  useEffect(() => {
    setApiError("");
  }, [
    selectedCountry,
    selectedCity,
    address,
    buildingName,
    shopName,
    mapPoints,
  ]);

  useEffect(() => {
    console.log("storeInfoData", storeInfoData);
    setSelectedCountry(storeInfoData.country);
    setSelectedCity(storeInfoData.city);
    setAddress(storeInfoData.address);
    setArea(storeInfoData.area);
    setBuildingName(storeInfoData.building);
    setShopName(storeInfoData.businessName);
    setMapPoints({
      lat: storeInfoData.latitude,
      lng: storeInfoData.longitude,
    });
  }, [storeInfoData]);

  return (
    <div className="w-full mt-0 md:mt-8 lg:mt-12 2xl:mt-[6.5rem]">
      <p className="text-base font-gtAmericaMedium text-smoky-black uppercase">
        STEP 4/5
      </p>
      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        STORE Info
      </h1>
      <p className="text-sm sm:text-lg 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3">
        Enter your store address
      </p>
      <form
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-3 mt-6 mb-20"
        onSubmit={(e) => {
          e.preventDefault();
          clickContinue();
        }}
      >
        <DropDown
          name="country"
          label="Country"
          key="country"
          options={countriesList}
          onChange={selectCoutry}
          selectedOption={selectedCountry}
          isNotSelected={isCountryNotSelected}
        />
        <DropDown
          name="city"
          label="City"
          key="city"
          options={
            selectedCountry === "United Arab Emirates"
              ? uaeCitiesList
              : selectedCountry === "Saudi Arabia"
                ? saudiArabiaCitiesList
                : []
          }
          noOptionLabel="First Select Country"
          onChange={selectCity}
          selectedOption={selectedCity}
          isNotSelected={isCityNotSelected}
        />
        <div className="col-span-full">
          <GoogleSearchAutoComplete
            onSelect={googleSearchAutoComplete}
            onChange={(e: string) => {
              setAddress(e);
              setIsAnyInputGotChanged(true);
            }}
            showError={isAddressError}
            inputValue={address}
            isLoaded={isLoaded}
          />
        </div>
        <Input
          name="buildingName"
          key="buildingName"
          label="Building name/#"
          placeholder="Building name/#"
          type="text"
          hasIcon={false}
          hasPostFix={false}
          value={buildingName}
          onChange={(e) => {
            setBuildingName(e.target.value);
            setIsBuildingNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isBuildingNameError}
        />
        <Input
          name="shopName"
          key="shopName"
          label="Shop name/#"
          placeholder="Shop name/#"
          type="text"
          hasIcon={false}
          hasPostFix={false}
          value={shopName}
          onChange={(e) => {
            setShopName(e.target.value);
            setIsShopNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isShopNameError}
        />
        <section className="col-span-full">
          <p className="text-lg font-gtAmericaMedium text-smoky-black">
            Pin your store location
          </p>
          <div
            className={`${isLocationSelectedNotOnMap
              ? "border-2 border-primary-red"
              : "border-0"
              }
          `}
          >
            <SelectMapLocation
              lat={mapPoints.lat}
              lng={mapPoints.lng}
              key="MapLocation"
              onSelect={selectLoction}
              isLoaded={isLoaded}
              showSearch={false}
            />
          </div>
        </section>
        <div className="mt-6 col-span-full flex items-center justify-between">
          <button
            className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
            type="submit"
            disabled={isAddingAddress}
          >
            {isAddingAddress ? "Adding Address" : "Continue"}
          </button>
          <button
            className="bg-transparent text-smoky-black text-sm font-gtAmericaMedium disabled:text-[#666666] disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
            type="button"
            onClick={() => {
              goBack();
            }}
          >
            Back
          </button>
        </div>
        {apiError && (
          <p className="mt-4 col-span-full text-base text-primary-red font-gtAmerica">
            {apiError}
          </p>
        )}
      </form>
    </div>
  );
}

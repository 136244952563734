import React from "react";
import Header from "../components/Header";

export default function ComingSoon() {
    return (
        <>
            <Header />
            <div className="h-[70vh] flex justify-center items-center">
                <h1 className="text-6xl font-gtAmericaBold">Coming Soon</h1>
            </div>
        </>
    );
}
import React from "react";
import strablLogo from "../images/strablLogo.svg";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import star from "../images/svgPaths/star.svg";
import { useNavigate } from "react-router-dom";

export default function SecondFooter() {
    const navigate = useNavigate();
    return (
        <>
            <footer className="relative pt-16 md:pt-20 pb-10 px-5 lg:px-16 xl:px-24 flex flex-col lg:flex-row justify-between items-start gap-5 border-t-4 border-black bg-white lg:bg-[#FF485C]">
                <div className="hidden lg:block relative w-44 2xl:w-48 border-2 border-black px-2 py-1 drop-shadow-[4px_4px_0_rgba(0,0,0,1)] bg-white">
                    <img src={strablLogo} width={836} height={233} alt="Strabl logo" />
                </div>
                <div>
                    <h3 className="text-sm 2xl:text-lg font-kineticaBold text-[#FF485C] lg:text-white">
                        Useful Links
                    </h3>
                    <div className="flex flex-col lg:flex-row items-start gap-0 lg:gap-24 md:my-3">
                        <ul className="text-sm 2xl:text-lg font-gtAmericaBold text-black lg:text-white">
                            <li className="mb-2">About Us</li>
                            <li className="mb-2">
                                <a href="/privacy-policy.pdf" target="_blank">
                                    Privacy Policy
                                </a>
                            </li>
                            <li className="mb-2">FAQ's</li>
                            <li
                                className="mb-2 cursor-pointer"
                                onClick={() => navigate("/blog/index.html")}
                            >
                                Blog
                            </li>
                        </ul>
                        <ul className="text-sm 2xl:text-lg font-gtAmericaBold text-black lg:text-white">
                            <li className="mb-2">Try Now Buy Later</li>
                            <li className="mb-2">
                                <a href="/merchant-terms.pdf" target="_blank">
                                    Merchant terms
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="/buyer-terms.pdf" target="_blank">
                                    Buyer Terms
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="max-w-none lg:max-w-[210px] 2xl:max-w-[350px]">
                    <h3 className="text-sm 2xl:text-lg font-kineticaBold text-[#FF485C] lg:text-white">
                        Head Office
                    </h3>
                    <div className="flex items-start gap-24 my-3">
                        <ul className="text-sm 2xl:text-lg font-gtAmericaBold">
                            <li className="mb-2 flex items-start gap-2 text-sm 2xl:text-lg text-black lg:text-white">
                                <FaMapMarkerAlt className="text-[#FF485C] md:text-white text-xl lg:text-3xl" />{" "}
                                In5, Dubai Design District, Dubai <br />
                                <br />
                                Al Sila tower, Regus Offices, Al Maryah Island, ADGM Square, Abu
                                Dhabi
                            </li>
                            <li className="mb-2 flex items-start gap-2 text-black lg:text-white">
                                <FaPhoneAlt className="text-[#FF485C] md:text-white text-xl" />
                                <a href="tel:+971545137675">+971 54 513 7675</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="max-w-[210px] 2xl:max-w-[350px]">
                    <h3 className="text-sm font-kineticaBold text-[#FF485C] md:text-white">
                        Social Links
                    </h3>
                    <div className="flex items-start gap-24 my-3">
                        <ul className="text-sm font-gtAmericaBold flex items-center gap-5">
                            <li className="flex items-start gap-2 bg-[#02D6E0] border-2 border-black text-xl sm:text-2xl 2xl:text-3xl rounded-full py-2 px-2 drop-shadow-[3px_4px_0_rgba(14,38,36,1)]">
                                <a
                                    href="https://www.facebook.com/STRABL.TNBL"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsFacebook className="text-black  " />
                                </a>
                            </li>
                            <li className="flex items-start gap-2 bg-[#02D6E0] border-2 border-black text-xl sm:text-2xl 2xl:text-3xl rounded-full py-2 px-2 drop-shadow-[3px_4px_0_rgba(14,38,36,1)]">
                                <a
                                    href="https://www.instagram.com/strabl_tnbl/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsInstagram className="text-black  " />
                                </a>
                            </li>
                            <li className="flex items-start gap-2 bg-[#02D6E0] border-2 border-black text-xl sm:text-2xl 2xl:text-3xl rounded-full py-2 px-2 drop-shadow-[3px_4px_0_rgba(14,38,36,1)]">
                                <a
                                    href="https://twitter.com/STRABL_TNBL"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsTwitter className="text-black  " />
                                </a>
                            </li>
                            <li className="flex items-start gap-2 bg-[#02D6E0] border-2 border-black text-xl sm:text-2xl 2xl:text-3xl rounded-full py-2 px-2 drop-shadow-[3px_4px_0_rgba(14,38,36,1)]">
                                <a
                                    href="https://www.linkedin.com/company/strabl/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsLinkedin className="text-black  " />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <img
                    src={star}
                    alt=""
                    className="absolute -top-[2.2rem] md:-top-[2.91rem] left-4 sm:left-5 w-20 md:w-auto"
                />
            </footer>
            <div className="bg-black text-white">
                <p className="text-center text-sm 2xl:text-lg font-gtAmericaBold py-2">
                    © {new Date().getFullYear()} Strabl. All rights reserved.
                </p>
            </div>
        </>
    );
}
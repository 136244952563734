import { createSlice } from "@reduxjs/toolkit";

const vendorRegisterSlice = createSlice({
  name: "vendorRegister",
  initialState: {
    currentStep: 1,
    contactDetails: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    businessInfo: {
      uuid: "",
      storeUrl: "",
      domainUrl: "",
      storeName: "",
      platformIdentifier: "",
    },
    appSetup: {
      adminAccessToken: "",
      frontAccessToken: "",
      apiKey: "",
      secretKey: "",
      credentialsAreAdded: false,
      isCredentialsVerified: false,
    },
    storeInfo: {
      businessName: "",
      address: "",
      city: "",
      country: "",
      area: "",
      building: "",
      streetName: "",
      latitude: "",
      longitude: "",
    },
    accountDetails: {
      accountName: "",
      bankName: "",
      accountNumber: "",
      iban: "",
      country: "",
      city: "",
    },
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },

    // to store Contact Details Inputs Data
    setContactDetails: (state, action) => {
      state.contactDetails.firstName = action.payload.firstName;
      state.contactDetails.lastName = action.payload.lastName;
      state.contactDetails.email = action.payload.email;
      state.contactDetails.phoneNumber = action.payload.phoneNumber;
    },

    // to store App Setup Inputs Data
    setBusinessInfo: (state, action) => {
      state.businessInfo.uuid = action.payload.uuid;
      state.businessInfo.storeName = action.payload.storeName;
      state.businessInfo.domainUrl = action.payload.domainUrl;
      state.businessInfo.storeUrl = action.payload.storeUrl;
      state.businessInfo.platformIdentifier = action.payload.platformIdentifier;
    },

    // to store App Setup Inputs Data
    setAppSetupDetails: (state, action) => {
      state.appSetup.adminAccessToken = action.payload.adminAccessToken;
      state.appSetup.frontAccessToken = action.payload.frontAccessToken;
      state.appSetup.apiKey = action.payload.apiKey;
      state.appSetup.secretKey = action.payload.secretKey;
      state.appSetup.credentialsAreAdded = action.payload.credentialsAreAdded;
      state.appSetup.isCredentialsVerified =
        action.payload.isCredentialsVerified;
    },

    // to store "Store Info" Inputs Data
    setStoreInfoDetails: (state, action) => {
      state.storeInfo.businessName = action.payload.businessName;
      state.storeInfo.address = action.payload.address;
      state.storeInfo.city = action.payload.city;
      state.storeInfo.country = action.payload.country;
      state.storeInfo.area = action.payload.area;
      state.storeInfo.building = action.payload.building;
      state.storeInfo.streetName = action.payload.streetName;
      state.storeInfo.latitude = action.payload.latitude;
      state.storeInfo.longitude = action.payload.longitude;
    },

    // to store "Store Info" Inputs Data
    setAccountDetails: (state, action) => {
      state.accountDetails.accountName = action.payload.accountName;
      state.accountDetails.bankName = action.payload.bankName;
      state.accountDetails.accountNumber = action.payload.accountNumber;
      state.accountDetails.iban = action.payload.iban;
      state.accountDetails.city = action.payload.city;
      state.accountDetails.country = action.payload.country;
    },

    // TO Clear all the State
    clearAllState: (state) => {
      state.contactDetails = {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      };
      state.businessInfo = {
        uuid: "",
        storeUrl: "",
        domainUrl: "",
        storeName: "",
        platformIdentifier: "",
      };
      state.appSetup = {
        adminAccessToken: "",
        frontAccessToken: "",
        apiKey: "",
        secretKey: "",
        credentialsAreAdded: false,
        isCredentialsVerified: false,
      };

      state.storeInfo = {
        businessName: "",
        address: "",
        city: "",
        country: "",
        area: "",
        building: "",
        streetName: "",
        latitude: "",
        longitude: "",
      };
      state.accountDetails = {
        accountName: "",
        bankName: "",
        accountNumber: "",
        iban: "",
        country: "",
        city: "",
      };
    },
  },
});

export const {
  setCurrentStep,
  setContactDetails,
  setBusinessInfo,
  setAppSetupDetails,
  setStoreInfoDetails,
  setAccountDetails,
  clearAllState,
} = vendorRegisterSlice.actions;
export default vendorRegisterSlice.reducer;

import React from "react";
import { Widget } from "@typeform/embed-react";
import underline2 from "../images/svgPaths/underline2.svg";
import shopify from "../images/brandLogos/shopify.png";
import wooCommerce from "../images/brandLogos/wooCommerce.png";
import salla from "../images/brandLogos/salla.png";
import openCart from "../images/brandLogos/openCart.png";
import appZid from "../images/brandLogos/app-zid.png";
import rightToLeftArrow from "../images/svgPaths/rightToLeftArrow.svg";
import Header from "../components/Header";
import SecondFooter from "../components/SecondFooter";

export default function PartnerWithUs() {
  return (
    <>
      <Header />
      <main className="min-h-screen">
        <div className="my-16 relative w-fit mx-5 sm:mx-auto">
          <h1 className="font-kineticaBold text-4xl text-center text-black tracking-tighter leading-10 uppercase">
            Partner with us
          </h1>
          <img
            src={underline2}
            alt="text underline"
            className="hidden lg:block absolute right-0 top-full"
          />
        </div>

        <section className="typeformSection my-10 mx-8 sm:mx-auto">
          <div className="w-full xl:w-[900px] h-[526px] xl:h-[400px]">
            <Widget id="DgRVQ03d" className="w-full h-full" />
          </div>
        </section>

        <section className="bg-[#EBF0FF] mt-10 sm:mt-20 py-12 sm:py-20 flex flex-col lg:flex-row justify-center items-center gap-10 relative">
          <section className="w-fit flex flex-col items-center">
            <h3 className="text-xl font-kineticaBold text-black tracking-tight uppercase">
              Available on
            </h3>
            <div className="h-20 flex justify-center items-center px-6 py-1 w-fit mt-1 bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={shopify}
                alt="Shopify"
                className="w-52"
                width={463}
                height={130}
              />
            </div>
          </section>
          <section className="w-fit flex flex-col items-center">
            <h3 className="text-xl font-kineticaBold text-black tracking-tight uppercase">
              Coming soon
            </h3>
            <div className="h-20 flex items-center justify-center gap-6 px-7 py-3 w-fit mt-1 bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={wooCommerce}
                alt="Woo Commerce"
                className="w-36 sm:w-44"
                width={362}
                height={75}
              />
              <img
                src={salla}
                alt="Salla"
                className="w-16 sm:w-20"
                width={186}
                height={100}
              />
              <img
                src={openCart}
                alt="Open Cart"
                className="w-44 hidden sm:block"
                width={374}
                height={79}
              />
              <img
                src={appZid}
                alt="App Zid"
                className="w-20 hidden sm:block"
                width={161}
                height={81}
              />
            </div>
            <div className="h-20 mt-5 flex sm:hidden items-center justify-center gap-6 px-7 py-3 w-fit bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={openCart}
                alt="Open Cart"
                className="w-36"
                width={374}
                height={79}
              />
              <img
                src={appZid}
                alt="App Zid"
                className="w-16"
                width={161}
                height={81}
              />
            </div>
          </section>
          <img
            src={rightToLeftArrow}
            alt="Arrow"
            className="hidden sm:block w-52 absolute -top-16 left-0 -scale-x-100 "
          />
        </section>
      </main>
      <SecondFooter />
    </>
  );
}
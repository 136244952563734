import React, { useEffect, useState } from "react";
import { BsTriangleFill } from "react-icons/bs";
import { MdInfoOutline } from "react-icons/md";
import Input from "../Input";
import DropDown from "../DropDown";
import axiosAPI from "../../utils/axiosAPI";
import { useDispatch, useSelector } from "react-redux";
import { setAccountDetails } from "../../redux/slices/vendorRegisterRedux";

type Props = {
  goNext: () => void;
  goBack: () => void;
};

export default function AccountDetails({ goNext, goBack }: Props) {
  const dispatch = useDispatch();
  // user Data with profile and Auth from Redux Store
  const user = useSelector((state: any) => state.user.userData);
  // Already Filled Inputs Data from Redux Store
  const accountDetailsData = useSelector(
    (state: any) => state.vendorRegister.accountDetails
  );

  // State to Check if Any Inputs Got Changed
  const [isAnyInputGotChanged, setIsAnyInputGotChanged] = useState(false);

  // State for Loading indicator when calling API
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  // States for Input values
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");

  // States for Country Dropdown
  const countriesList = ["United Arab Emirates", "Saudi Arabia"];
  const [selectedCountry, setSelectedCountry] = useState("");

  // States for City Dropdown
  const [selectedCity, setSelectedCity] = useState("");

  // States for Validation errors for inputs
  const [isAccountNameError, setIsAccountNameError] = useState(false);
  const [isAccountNumberError, setIsAccountNumberError] = useState(false);
  const [isIbanError, setIsIbanError] = useState(false);
  const [isBankNameError, setIsBankNameError] = useState(false);
  const [isCountryNotSelected, setIsCountryNotSelected] = useState(false);
  const [isCityNotSelected, setIsCityNotSelected] = useState(false);

  // State to show API Error Response
  const [apiError, setApiError] = useState("");

  // Function for Dropdown to select Country
  const selectCoutry = (country: string) => {
    setSelectedCountry(country);
    setIsCountryNotSelected(false);
    setSelectedCity("");
    setIsAnyInputGotChanged(true);
  };

  // Function for Dropdown to select City
  const selectCity = (city: string) => {
    setSelectedCity(city);
    setIsCityNotSelected(false);
    setIsAnyInputGotChanged(true);
  };

  // Country wise Cities lists
  const uaeCitiesList = [
    "Dubai",
    "Abu Dhabi",
    "Sharjah",
    "Ajman",
    "Fujairah",
    "Ras Al Khaimah",
    "Umm Al Quwain",
  ];

  const saudiArabiaCitiesList = [
    "Riyadh",
    "Jeddah",
    "Mecca",
    "Medina",
    "Dammam",
    "Tabuk",
    "Buraidah",
    "Abha",
    "Hail",
    "Arar",
    "Najran",
    "Jizan",
    "Al Bahah",
    "Sakakah",
  ];

  // Check for all required fields and go next
  const clickContinue = () => {
    if (
      accountName &&
      accountName.length > 3 &&
      accountNumber &&
      accountNumber.match(/^[0-9]+$/) &&
      iban &&
      iban.length > 10 &&
      iban.length < 35 &&
      bankName &&
      selectedCountry &&
      selectedCity
    ) {
      setIsAddingAccount(true);

      // Check if Nothing got Changed then Go Next
      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }

      // Add bank to user
      axiosAPI
        .post(
          "/v1/api/user/bank/",
          {
            accountName: accountName,
            bankName: bankName,
            accountNumber: accountNumber,
            iban: iban,
            country: selectedCountry,
            city: selectedCity,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .then((res) => {
          // Store Filled Inputs data for Later use
          dispatch(
            setAccountDetails({
              accountName: accountName,
              accountNumber: accountNumber,
              bankName: bankName,
              city: selectedCity,
              country: selectedCountry,
              iban: iban,
            })
          );
          setIsAddingAccount(false);
          goNext();
        })
        .catch((error) => {
          setIsAddingAccount(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check for all the missing input and show error

      // Check if Account Name is entered or not and check its length
      if (!accountName || accountName.length < 3) {
        setIsAccountNameError(true);
      }

      // Check if Account Number is entered or not and check it contains only numbers
      if (!accountNumber || !accountNumber.match(/^[0-9]+$/)) {
        setIsAccountNumberError(true);
      }

      // Check if IBAN is entered or not and check its length
      if (!iban || iban.length < 10 || iban.length > 35) {
        setIsIbanError(true);
      }

      // Check if Bank Name is entered or not
      if (!bankName) {
        setIsBankNameError(true);
      }

      // Check if Country is selected or not
      if (!selectedCountry) {
        setIsCountryNotSelected(true);
      }

      // Check if City is selected or not
      if (!selectedCity) {
        setIsCityNotSelected(true);
      }
    }
  };

  // Hide API error response when user changes any field
  useEffect(() => {
    setApiError("");
  }, [
    bankName,
    accountName,
    accountNumber,
    iban,
    selectedCountry,
    selectedCity,
  ]);

  useEffect(() => {
    console.log("accountDetailsData", accountDetailsData);
    setAccountName(accountDetailsData.accountName);
    setAccountNumber(accountDetailsData.accountNumber);
    setBankName(accountDetailsData.bankName);
    setSelectedCity(accountDetailsData.city);
    setSelectedCountry(accountDetailsData.country);
    setIban(accountDetailsData.iban);
  }, [accountDetailsData]);

  return (
    <div className="w-full">
      <p className="text-base font-gtAmericaMedium text-smoky-black uppercase">
        STEP 5/5
      </p>
      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        ACCOUNT DETAILS
      </h1>
      <p className="text-sm sm:text-lg 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3 flex items-center gap-x-1">
        Enter your bank account details.
        <div className="group relative flex items-center">
          <MdInfoOutline className="text-xl cursor-pointer" />
          <div className="absolute left-8 hidden group-hover:flex items-center min-w-max bg-smoky-black pt-2 pb-1 px-2 rounded text-lg font-gtAmericaMedium text-white">
            <BsTriangleFill className="-rotate-90 w-5 h-5 2xl:w-8 2xl:h-8 mb-1 absolute -left-3 text-smoky-black z-1" />
            <span className="text-xs 2xl:text-lg bg-smoky-black z-[2]">
              For Payouts
            </span>
          </div>
        </div>
      </p>
      <form
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-3 mt-6"
        onSubmit={(e) => {
          e.preventDefault();
          clickContinue();
        }}
      >
        <div className="col-span-full">
          <Input
            name="accountName"
            label="Account Name"
            hasIcon={false}
            placeholder="Account Name"
            type="text"
            key="accountName"
            maxLength={70}
            minLength={3}
            value={accountName}
            onChange={(e) => {
              setAccountName(e.target.value.replace(/[^a-zA-Z ]/g, ""));
              setIsAccountNameError(false);
              setIsAnyInputGotChanged(true);
            }}
            showError={isAccountNameError}
          />
        </div>
        <div className="col-span-full">
          <Input
            name="AccountNumber"
            label="Account Number"
            hasIcon={false}
            placeholder="Account Number"
            type="number"
            key="accountNumber"
            maxLength={25}
            value={accountNumber}
            onChange={(e) => {
              if (e.target.value.length < 26) {
                setAccountNumber(e.target.value);
                setIsAccountNumberError(false);
                setIsAnyInputGotChanged(true);
              }
            }}
            showError={isAccountNumberError}
          />
        </div>
        <Input
          name="IBAN"
          label="IBAN"
          hasIcon={false}
          placeholder="IBAN"
          type="text"
          key="IBAN"
          maxLength={34}
          value={iban}
          onChange={(e) => {
            setIban(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ""));
            setIsIbanError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isIbanError}
        />
        <Input
          name="bankName"
          label="Bank Name"
          hasIcon={false}
          placeholder="Bank Name"
          type="text"
          key="Bank Name"
          maxLength={100}
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value.replace(/[^a-zA-Z ]/g, ""));
            setIsBankNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isBankNameError}
        />
        <DropDown
          name="country"
          label="Country"
          key="country"
          options={countriesList}
          onChange={selectCoutry}
          selectedOption={selectedCountry}
          isNotSelected={isCountryNotSelected}
        />
        <DropDown
          name="city"
          label="City"
          key="city"
          options={
            selectedCountry === "United Arab Emirates"
              ? uaeCitiesList
              : selectedCountry === "Saudi Arabia"
              ? saudiArabiaCitiesList
              : []
          }
          noOptionLabel="First Select Country"
          onChange={selectCity}
          selectedOption={selectedCity}
          isNotSelected={isCityNotSelected}
        />
        <div className="mt-4 col-span-full flex items-center justify-between">
          <div className="flex items-center gap-x-2.5">
            <button
              className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
              type="submit"
              disabled={isAddingAccount}
            >
              {isAddingAccount ? "Adding..." : "Continue"}
            </button>
            <button
              className="bg-white text-smoky-black text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
              onClick={() => {
                goNext();
              }}
              type="button"
              disabled={isAddingAccount}
            >
              skip
            </button>
          </div>
          <button
            className="bg-transparent text-smoky-black text-sm font-gtAmericaMedium disabled:text-[#666666] disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
            type="button"
            onClick={() => {
              goBack();
            }}
          >
            Back
          </button>
        </div>
        {apiError && (
          <p className="mt-4 col-span-full text-base text-primary-red font-gtAmerica">
            {apiError}
          </p>
        )}
      </form>
    </div>
  );
}

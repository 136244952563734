import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Input from "../Input";
import axiosAPI from "../../utils/axiosAPI";
import { useDispatch, useSelector } from "react-redux";
import { setAppSetupDetails } from "../../redux/slices/vendorRegisterRedux";

type Props = {
  goNext: () => void;
  goBack: () => void;
};

export default function AppSetup({ goNext, goBack }: Props) {
  const dispatch = useDispatch();
  // user Data with profile and Auth from Redux Store
  const user = useSelector((state: any) => state.user.userData);
  // Business Info data added in previous step from Redux Store
  const buisnessInfo = useSelector(
    (state: any) => state.vendorRegister.businessInfo
  );
  // Already Filled Inputs Data from Redux Store
  const appSetupData = useSelector(
    (state: any) => state.vendorRegister.appSetup
  );

  // State to Check if Any Inputs Got Changed
  const [isAnyInputGotChanged, setIsAnyInputGotChanged] = useState(false);

  // State to store platform uuid for later use. Received from API
  const [merchantPlatformUuid, setMerchantPlatformUuid] = useState("");

  // State to show/hide "Create App Instructions" Tab
  const [showCreateAppInstructions, setShowCreateAppInstructions] =
    useState(false);

  // State to show/hide "Enter API Credentials" Tab
  const [showEnterAPICredentials, setShowEnterAPICredentials] = useState(false);

  // States to handle Loading indicators while calling APIs
  const [credentialsAreAdded, setCredentialsAreAdded] = useState(false);
  const [isAddingCredentials, setIsAddingCredentials] = useState(false);
  const [isVerifyingCredentials, setIsVerifyingCredentials] = useState(false);

  // State to show API Error Response
  const [apiError, setApiError] = useState("");

  // States for Inputs
  const [adminAPIAccessToken, setAdminAPIAccessToken] = useState("");
  const [storefrontAPIAccessToken, setStorefrontAPIAccessToken] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiSecretKey, setApiSecretKey] = useState("");

  // State to check if Credentials are varified or not
  const [isCredentialsVerified, setIsCredentialsVerified] = useState(false);

  // States for Validation Errors
  const [isAdminAPIAccessTokenError, setIsAdminAPIAccessTokenError] =
    useState(false);
  const [isStorefrontAPIAccessTokenError, setIsStorefrontAPIAccessTokenError] =
    useState(false);
  const [isApiKeyError, setIsApiKeyError] = useState(false);
  const [isApiSecretKeyError, setIsApiSecretKeyError] = useState(false);

  // Verify Token Credentials
  const verifyCredentials = () => {
    if (
      adminAPIAccessToken &&
      storefrontAPIAccessToken &&
      apiKey &&
      apiSecretKey
    ) {
      setIsVerifyingCredentials(true);

      // Check if Nothing got Changed then Go Next
      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }
      axiosAPI
        .get(`/v1/api/user/merchant/verify/${merchantPlatformUuid}`, {
          headers: {
            Authorization: `Bearer ${user.auth.token}`,
          },
        })
        .then((res) => {
          setIsVerifyingCredentials(false);
          if (res?.data?.data?.verified) {
            setIsCredentialsVerified(true);
          }
          dispatch(
            setAppSetupDetails({
              adminAccessToken: adminAPIAccessToken,
              apiKey: apiKey,
              frontAccessToken: storefrontAPIAccessToken,
              secretKey: apiSecretKey,
              credentialsAreAdded: true,
              isCredentialsVerified: res?.data?.data?.verified,
            })
          );
        })
        .catch((error) => {
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
          setIsVerifyingCredentials(false);
        });
    } else {
      // Check if Admin API Access Token is entered or not
      if (!adminAPIAccessToken) {
        setIsAdminAPIAccessTokenError(true);
      }

      // Check if Store Front API Access Token is entered or not
      if (!storefrontAPIAccessToken) {
        setIsStorefrontAPIAccessTokenError(true);
      }

      // Check if API Key is entered or not
      if (!apiKey) {
        setIsApiKeyError(true);
      }

      // Check if API Secret Key is entered or not
      if (!apiSecretKey) {
        setIsApiSecretKeyError(true);
      }
    }
  };

  // Add Token Credentials
  const addCredentials = () => {
    if (
      adminAPIAccessToken &&
      storefrontAPIAccessToken &&
      apiKey &&
      apiSecretKey
    ) {
      setIsAddingCredentials(true);

      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }

      axiosAPI
        .put(
          `/v1/api/user/merchant/${buisnessInfo?.uuid}`,
          {
            adminAccessToken: adminAPIAccessToken,
            frontAccessToken: storefrontAPIAccessToken,
            apiKey: apiKey,
            secretKey: apiSecretKey,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .then((res) => {
          setMerchantPlatformUuid(res.data.data.uuid);
          dispatch(
            setAppSetupDetails({
              adminAccessToken: adminAPIAccessToken,
              apiKey: apiKey,
              frontAccessToken: storefrontAPIAccessToken,
              secretKey: apiSecretKey,
              credentialsAreAdded: true,
              isCredentialsVerified: false,
            })
          );
          setCredentialsAreAdded(true);
          setIsAddingCredentials(false);
        })
        .catch((error) => {
          setIsAddingCredentials(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check if Admin API Access Token is entered or not
      if (!adminAPIAccessToken) {
        setIsAdminAPIAccessTokenError(true);
      }

      // Check if Store Front API Access Token is entered or not
      if (!storefrontAPIAccessToken) {
        setIsStorefrontAPIAccessTokenError(true);
      }

      // Check if API Key is entered or not
      if (!apiKey) {
        setIsApiKeyError(true);
      }

      // Check if API Secret Key is entered or not
      if (!apiSecretKey) {
        setIsApiSecretKeyError(true);
      }
    }
  };

  useEffect(() => {
    setApiError("");
    // setCredentialsAreAdded(false);
  }, [adminAPIAccessToken, storefrontAPIAccessToken, apiKey, apiSecretKey]);

  useEffect(() => {
    console.log("appSetupData", appSetupData);
    setAdminAPIAccessToken(appSetupData.adminAccessToken);
    setStorefrontAPIAccessToken(appSetupData.frontAccessToken);
    setApiKey(appSetupData.apiKey);
    setApiSecretKey(appSetupData.secretKey);
    setCredentialsAreAdded(appSetupData.credentialsAreAdded);
    setIsCredentialsVerified(appSetupData.isCredentialsVerified);
  }, [appSetupData]);

  return (
    <section
      className={`w-full ${
        showCreateAppInstructions || showEnterAPICredentials
          ? "mt-0 md:mt-[4.5rem]"
          : "mt-0"
      }`}
    >
      <p className="text-base font-gtAmericaMedium text-smoky-black uppercase">
        STEP 3/5
      </p>
      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        APP SETUP
      </h1>
      <p className="text-sm sm:text-lg 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3">
        To integrate STRABL into your store, you will need to install a Custom
        Shopify app. This will give us the permissions necessary to power your
        new Try Now Buy Later program.
      </p>
      <section className="mt-8 mb-32">
        {/* Tab for - Step 1: Create the STRABL App */}
        <section className="border-b border-black overflow-hidden">
          <button
            className="flex justify-between items-center w-full pb-3"
            onClick={() => {
              setShowCreateAppInstructions(!showCreateAppInstructions);
            }}
          >
            <span className="text-base sm:text-xl 2xl:text-[22px] font-gtAmericaBold text-smoky-black">
              Step 1: Create the STRABL App
            </span>
            <FiChevronDown
              className={`text-smoky-black text-xl transition-all duration-300 ease-in-out ${
                showCreateAppInstructions ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
          <section
            className={`text-base font-gtAmerica text-smoky-black transition-all duration-300 ease-in-out
                ${
                  showCreateAppInstructions
                    ? "translate-y-0 h-auto"
                    : "translate-y-full h-0 "
                }
            `}
          >
            <div className="pt-6 pb-4">
              <p className="text-base font-gtAmerica text-smoky-black">
                From your store's Admin, go to "Apps" and follow these steps:
              </p>
              <br />
              <ul className="list-decimal list-inside leading-7">
                <li>
                  Click "App and sales channel settings" in the bottom of the
                  modal popup.
                </li>
                <li>Click "Develop apps for your store".</li>
                <li>
                  Click "Create an app". Enter app name, you may use any name
                  you like.
                </li>

                <li>Set App developer to your store's admin account.</li>
                <li>Click "Create app".</li>
                <li>Click "Configure Admin API scopes".</li>
                <li>Set all of the following permissions and save:</li>
                <li>
                  Required Permissions
                  <ul className="list-inside ml-5 list-[lower-alpha]">
                    <li>Analytics: Read and write</li>
                    <li>Customers: Read and write</li>
                    <li>Discounts: Read and write</li>
                    <li>Draft orders: Read and write</li>
                    <li>Fulfilment services: Read and write</li>
                    <li>Inventory: Read and write</li>
                    <li>Locations: Read access</li>
                    <li>Order editing: Read and write</li>
                    <li>Orders: Read and write</li>
                    <li>Payment terms: Read and write</li>
                    <li>Price rules: Read and write</li>
                    <li>Product listings: Read and write</li>
                    <li>Products: Read and write</li>
                    <li>Reports: Read access</li>
                    <li>Shipping: Read and write</li>
                    <li>Shop Locale: Read and write</li>
                    <li>Themes: Read access</li>
                  </ul>
                </li>

                <li>
                  Navigate back to the "Overview" tab and Click "Configure
                  Storefront API scopes".
                </li>
                <li>Set the following permissions and save:</li>
                <li>Required Permissions</li>
                <ul className="list-inside ml-5 list-[lower-alpha]">
                  <li>Checkout:</li>
                  <ul className="list-inside ml-5 list-disc marker:text-smoky-black marker:text-sm">
                    <li>unauthenticated_write_checkouts</li>
                    <li>unauthenticated_read_checkouts</li>
                  </ul>
                  <li>Customers:</li>
                  <ul className="list-inside ml-5 list-disc marker:text-smoky-black marker:text-sm">
                    <li>unauthenticated_write_customers</li>
                    <li>unauthenticated_read_customers</li>
                    <li>unauthenticated_read_customer_tags</li>
                  </ul>
                  <li>Products:</li>
                  <ul className="list-inside ml-5 list-disc marker:text-smoky-black marker:text-sm">
                    <li>unauthenticated_read_product_listings</li>
                    <li>unauthenticated_read_product_inventory</li>
                    <li>unauthenticated_read_product_pickup_locations</li>
                    <li>unauthenticated_read_product_tags</li>
                  </ul>
                  <li>Selling plans:</li>
                  <ul className="list-inside ml-5 list-disc marker:text-smoky-black marker:text-sm">
                    <li>unauthenticated_read_selling_plans</li>
                  </ul>
                </ul>
                <li>Click "Install App".</li>
                <li>You will need your API credentials for step 2.</li>
              </ul>
            </div>
          </section>
        </section>

        {/* Tab for - Step 2: Enter API Credentials */}
        <section className="border-b border-black overflow-hidden mt-12">
          <button
            className="flex justify-between items-center w-full pb-3"
            onClick={() => {
              setShowEnterAPICredentials(!showEnterAPICredentials);
            }}
          >
            <span className="text-base sm:text-xl 2xl:text-[22px] font-gtAmericaBold text-smoky-black">
              Step 2: Enter API Credentials
            </span>
            <FiChevronDown
              className={`text-smoky-black text-xl transition-all duration-300 ease-in-out ${
                showEnterAPICredentials ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
          <section
            className={`text-base font-gtAmerica text-smoky-black transition-all duration-300 ease-in-out
                ${
                  showEnterAPICredentials
                    ? "translate-y-0 h-auto"
                    : "translate-y-full h-0"
                }
            `}
          >
            <form
              className="pt-6 pb-4 grid grid-cols-1 gap-y-3"
              onSubmit={(e) => {
                e.preventDefault();
                // verifyCredentials();
              }}
            >
              <Input
                name="adminAPIAccessToken"
                label="Admin API access token"
                hasIcon={false}
                placeholder="Admin API access token"
                type="text"
                key="adminAPIAccessToken"
                maxLength={50}
                value={adminAPIAccessToken}
                onChange={(e) => {
                  setAdminAPIAccessToken(e.target.value);
                  setIsAdminAPIAccessTokenError(false);
                  setIsAnyInputGotChanged(true);
                }}
                showError={isAdminAPIAccessTokenError}
              />

              <Input
                key="storefrontAPIAccessToken"
                name="storefrontAPIAccessToken"
                label="Storefront API access token"
                hasIcon={false}
                placeholder="Storefront API access token"
                type="text"
                maxLength={50}
                value={storefrontAPIAccessToken}
                onChange={(e) => {
                  setStorefrontAPIAccessToken(e.target.value);
                  setIsStorefrontAPIAccessTokenError(false);
                  setIsAnyInputGotChanged(true);
                }}
                showError={isStorefrontAPIAccessTokenError}
              />
              <Input
                key="apiKey"
                name="apiKey"
                label="API key"
                hasIcon={false}
                placeholder="API key"
                type="text"
                maxLength={50}
                value={apiKey}
                onChange={(e) => {
                  setApiKey(e.target.value);
                  setIsApiKeyError(false);
                  setIsAnyInputGotChanged(true);
                }}
                showError={isApiKeyError}
              />
              <Input
                key="apiSecretKey"
                name="apiSecretKey"
                label="API Secret key"
                hasIcon={false}
                placeholder="API Secret key"
                type="text"
                maxLength={50}
                value={apiSecretKey}
                onChange={(e) => {
                  setApiSecretKey(e.target.value);
                  setIsApiSecretKeyError(false);
                  setIsAnyInputGotChanged(true);
                }}
                showError={isApiSecretKeyError}
              />
              <div className="mt-4">
                {credentialsAreAdded ? (
                  <button
                    className="border border-black bg-smoky-black hover:bg-[#454545] text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded disabled:bg-smoky-black disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
                    type="button"
                    disabled={isVerifyingCredentials || isCredentialsVerified}
                    onClick={() => {
                      verifyCredentials();
                    }}
                  >
                    {isVerifyingCredentials
                      ? "verifying CREDENTIALS"
                      : "Verify CREDENTIALS"}
                  </button>
                ) : (
                  <button
                    className="border border-black bg-smoky-black hover:bg-[#454545] text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded disabled:bg-smoky-black disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
                    type="button"
                    onClick={() => {
                      addCredentials();
                    }}
                    disabled={isAddingCredentials || credentialsAreAdded}
                  >
                    {isAddingCredentials
                      ? "Adding CREDENTIALS"
                      : "Add CREDENTIALS"}
                  </button>
                )}
                {apiError && (
                  <p className="mt-4 text-base text-primary-red font-gtAmerica">
                    {apiError}
                  </p>
                )}
              </div>
            </form>
          </section>
        </section>
        <div className="mt-8 flex items-center justify-between">
          <button
            className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
            type="button"
            disabled={!credentialsAreAdded || !isCredentialsVerified}
            onClick={() => {
              goNext();
            }}
          >
            Continue
          </button>
          <button
            className="bg-transparent text-smoky-black text-sm font-gtAmericaMedium disabled:text-[#666666] disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
            type="button"
            onClick={() => {
              goBack();
            }}
          >
            Back
          </button>
        </div>
      </section>
    </section>
  );
}

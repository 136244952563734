import React from "react";
import strablLogo from "../images/strablLogo.svg";
import clothingStore from "../images/clothingStore.png";
import ContactDetails from "../components/vendorRegistrationSteps/ContactDetails";
import BusinessInfo from "../components/vendorRegistrationSteps/BusinessInfo";
import AppSetup from "../components/vendorRegistrationSteps/AppSetup";
import StoreInfo from "../components/vendorRegistrationSteps/StoreInfo";
import AccountDetails from "../components/vendorRegistrationSteps/AccountDetails";
import OtpVerification from "../components/vendorRegistrationSteps/OtpVerification";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../redux/slices/vendorRegisterRedux";
import { HiOutlineArrowLeft } from "react-icons/hi";

export default function Register() {
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state: any) => state.vendorRegister.currentStep
  );

  // State to handle Steps to show
  // Step 1: Get Contact Details
  // Step 2: Get Business Info
  // Step 3: Show App Setup
  // Step 4: Get Store Info
  // Step 5: Get Account Details
  // Step 6: Show Opt Verification

  // Variables to handle Url navigation & Search Params
  const navigate = useNavigate();

  // Function to handle Go Next
  const goNext = () => {
    // navigate to next step
    navigate(`/register?step=${currentStep + 1}`);
    dispatch(setCurrentStep(currentStep + 1));
  };

  // Function to handle Go Back
  const goBack = () => {
    // navigate to previous step
    dispatch(setCurrentStep(currentStep - 1));
    navigate(`/register?step=${currentStep - 1}`);
  };

  return (
    <main className="grid grid-cols-12 gap-x-5 pt-16 md:pt-0">
      <header className="col-span-full bg-tertiary-blue md:bg-transparent z-50 md:z-10 flex items-center justify-center md:justify-between px-[1.125rem] md:px-[72px] py-4 md:py-[14px] fixed top-0 w-full">
        <button
          className={`bg-transparent h-fit w-9 mr-auto ${currentStep === 1 ? "block md:hidden" : "hidden"
            }`}
          onClick={() => {
            window.history.back();
          }}
        >
          <HiOutlineArrowLeft className="text-white text-2xl" />
        </button>
        <img
          src={strablLogo}
          alt="Strabl"
          className="cursor-pointer w-28 xl:w-32 bg-white px-2 py-1 md:py-[9px] rounded md:rounded-none border border-black drop-shadow-[2px_2px_0px_rgba(0,0,0,1)] md:drop-shadow-[4px_4px_0px_rgba(0,0,0,1)]"
          onClick={() => {
            navigate("/");
          }}
        />
        <button
          className={`text-white md:text-dashboard-menu font-gtAmerica text-sm w-9 ml-auto ${currentStep === 1 ? "block" : "hidden md:block"
            }`}
        >
          ENG
        </button>
      </header>
      <section
        className={`-mt-12 md:mt-0 col-span-12 md:col-span-6 overflow-hidden object-cover object-bottom h-auto md:h-screen static md:sticky top-0 z-0 ${currentStep === 1 ? "block" : "hidden md:block"
          }`}
      >
        <img
          src={clothingStore}
          alt="Clothing Store"
          className="object-cover object-bottom w-full h-full aspect-[6/5] sm:aspect-[3/2.2] md:aspect-auto"
        />
      </section>
      <section className="col-span-12 md:col-span-6 min-h-min md:min-h-screen flex flex-col items-start justify-center py-10 sm:py-16 pl-9 sm:pl-16 md:pl-5 lg:pl-11 pr-9 sm:pr-16 md:pr-9 lg:pr-24 xl:pr-32">
        {currentStep === 1 ? (
          <ContactDetails goNext={goNext} />
        ) : currentStep === 2 ? (
          <BusinessInfo goNext={goNext} goBack={goBack} />
        ) : currentStep === 3 ? (
          <AppSetup goNext={goNext} goBack={goBack} />
        ) : currentStep === 4 ? (
          <StoreInfo goNext={goNext} goBack={goBack} />
        ) : currentStep === 5 ? (
          <AccountDetails goNext={goNext} goBack={goBack} />
        ) : currentStep === 6 ? (
          <OtpVerification />
        ) : (
          <ContactDetails goNext={goNext} />
        )}
      </section>
    </main>
  );
}

import axios from "axios";

const isDebug =
  !localStorage.getItem("debug") ||
  localStorage.getItem("debug")?.toString() === "false"
    ? false
    : true;

const baseURL = isDebug
  ? "https://dev-api.strabl.com"
  : "https://api.strabl.com";

export default axios.create({
  baseURL: baseURL,
});

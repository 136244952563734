import React, { useEffect, useState } from "react";
import Input from "../Input";
import DropDown from "../DropDown";
import { useDispatch, useSelector } from "react-redux";
import axiosAPI from "../../utils/axiosAPI";
import { setBusinessInfo } from "../../redux/slices/vendorRegisterRedux";

type Props = {
  goNext: () => void;
  goBack: () => void;
};

export default function BusinessInfo({ goNext, goBack }: Props) {
  // Regex to validate url
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

  const dispath = useDispatch();

  // Already Filled Inputs Data from Redux Store
  const businessInfoData = useSelector(
    (state: any) => state.vendorRegister.businessInfo
  );

  // State to Check if Any Inputs Got Changed
  const [isAnyInputGotChanged, setIsAnyInputGotChanged] = useState(false);

  // user Data with profile and Auth from Redux Store
  const user = useSelector((state: any) => state.user.userData);

  // State for loading indicators while calling API
  const [isAddingBusinessInfo, setIsAddingBusinessInfo] = useState(false);

  // State to show Error Response from API
  const [apiError, setApiError] = useState("");

  // States for Platform Dropdown
  const platformsList = ["Shopify"];
  const [selectedPlatform, setSelectedPlatform] = useState("");

  // States for Inputs
  const [storeName, setStoreName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [storeDomain, setStoreDomain] = useState("");

  // States for Validation Errors
  const [isStoreNameError, setIsStoreNameError] = useState(false);
  const [isStoreUrlError, setIsStoreUrlError] = useState(false);
  const [isStoreDomainError, setIsStoreDomainError] = useState(false);
  const [isPlatformError, setIsPlatformError] = useState(false);

  // Check for all required fields and go next
  const clickContinue = () => {
    // Check if all required fields are entered then go next
    if (
      storeName &&
      storeUrl &&
      urlRegex.test(storeUrl) &&
      storeDomain &&
      urlRegex.test(storeDomain) &&
      selectedPlatform
    ) {
      // Check if Nothing Got Changed then go Next
      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }

      setIsAddingBusinessInfo(true);
      axiosAPI
        .post(
          "/v1/api/user/merchant/",
          {
            storeUrl: storeUrl,
            domainUrl: storeDomain,
            storeName: storeName,
            storeId: "",
            adminAccessToken: "",
            frontAccessToken: "",
            apiKey: "",
            secretKey: "",
            platformIdentifier: 1, //1:Shopify, 2:Zid, 3:Sella,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .then((res) => {
          dispath(
            setBusinessInfo({
              uuid: res.data.data.uuid,
              storeName: storeName,
              domainUrl: storeDomain,
              storeUrl: storeUrl,
              platformIdentifier: selectedPlatform,
            })
          );
          setIsAddingBusinessInfo(false);
          goNext();
        })
        .catch((error) => {
          setIsAddingBusinessInfo(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check if Store Name is entered or not
      if (!storeName) {
        setIsStoreNameError(true);
      }

      // Check if Store Url is entered or not
      if (!storeUrl || !urlRegex.test(storeUrl)) {
        setIsStoreUrlError(true);
      }

      // Check if Store Domain is entered or not
      if (!storeDomain || !urlRegex.test(storeDomain)) {
        setIsStoreDomainError(true);
      }

      // Check if Paltform is selected or or not
      if (!selectedPlatform) {
        setIsPlatformError(true);
      }
    }
  };

  useEffect(() => {
    setApiError("");
  }, [storeName, storeUrl, selectedPlatform, storeDomain]);

  // function to select platform from dropdown
  const selectPlatform = (platform: string) => {
    setSelectedPlatform(platform);
    setIsPlatformError(false);
    setIsAnyInputGotChanged(true);
  };

  useEffect(() => {
    setStoreUrl(businessInfoData.storeUrl);
    setStoreDomain(businessInfoData.domainUrl);
    setStoreName(businessInfoData.storeName);
    setSelectedPlatform(businessInfoData.platformIdentifier);
  }, [businessInfoData]);

  return (
    <div className="w-full 2xl:w-10/12">
      <p className="text-base font-gtAmericaMedium text-smoky-black uppercase">
        STEP 2/5
      </p>
      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        Business Info
      </h1>
      <p className="text-sm sm:text-lg 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3">
        We're excited to have you on board! Let's make the magic happen!
      </p>
      <form
        className="grid grid-cols-1 gap-x-2 gap-y-3 mt-6"
        onSubmit={(e) => {
          e.preventDefault();
          clickContinue();
        }}
      >
        <Input
          name="storeName"
          label="Store Name"
          hasIcon={false}
          placeholder="Store Name"
          type="text"
          key="storeName"
          maxLength={100}
          value={storeName}
          onChange={(e) => {
            setStoreName(e.target.value);
            setIsStoreNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isStoreNameError}
        />
        <Input
          name="storeUrl"
          label="Store URL"
          hasIcon={false}
          placeholder="Store URL"
          type="text"
          key="storeUrl"
          maxLength={100}
          value={storeUrl}
          onChange={(e) => {
            setStoreUrl(e.target.value);
            setIsAnyInputGotChanged(true);
            if (urlRegex.test(e.target.value)) {
              setIsStoreUrlError(false);
            } else {
              setIsStoreUrlError(true);
            }
          }}
          showError={isStoreUrlError}
        />
        <DropDown
          label="Platform"
          name="platform"
          key="platform"
          options={platformsList}
          selectedOption={selectedPlatform}
          isNotSelected={isPlatformError}
          onChange={selectPlatform}
        />
        {/* if Platform is selected then show Domain input */}
        {selectedPlatform !== "" && (
          <Input
            name="storeDomain"
            label="Domain"
            hasIcon={false}
            placeholder="Domain"
            type="text"
            key="storeDomain"
            maxLength={100}
            value={storeDomain}
            hasPostFix={selectedPlatform === "Shopify"}
            postFix=".myshopify.com"
            onChange={(e) => {
              setStoreDomain(e.target.value);
              setIsAnyInputGotChanged(true);
              if (urlRegex.test(e.target.value)) {
                setIsStoreDomainError(false);
              } else {
                setIsStoreDomainError(true);
              }
            }}
            showError={isStoreDomainError}
          />
        )}
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <button
              className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded border border-black shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:bg-[#cccccc] disabled:text-[#666666] disabled:cursor-not-allowed disabled:shadow-none disabled:border-0 transition-all duration-150 ease-in-out"
              type="submit"
              disabled={isAddingBusinessInfo}
            >
              {isAddingBusinessInfo ? "Adding..." : "Continue"}
            </button>
            <button
              className="text-sm font-gtAmericaMedium text-smoky-black bg-transparent"
              type="button"
              onClick={() => {
                goBack();
              }}
              disabled={isAddingBusinessInfo}
            >
              Back
            </button>
          </div>
          {apiError && (
            <p className="mt-4 text-sm sm:text-base text-primary-red font-gtAmerica">
              {apiError}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    isOldUser: false,
    forgetEmail: null,
    rememberMe: false,
    loginTime: 0,
    isEmailVerified: false,
    isPhoneVerified: false,
  },
  reducers: {
    addUser: (state, action) => {
      const time = new Date();
      state.userData = action.payload;
      state.loginTime = Math.floor(time.getTime() / 60000);
      state.isEmailVerified = null;
      state.isPhoneVerified = null;
    },
    logout: (state, action) => {
      state.userData = null;
      state.rememberMe = false;
      state.loginTime = 0;
      state.forgetEmail = null;
      state.isOldUser = false;
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, profile: action.payload };
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    setForgetEmail: (state, action) => {
      state.forgetEmail = action.payload;
    },
    setOldUser: (state, action) => {
      state.isOldUser = action.payload;
    },
    setEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload;
    },
    setPhoneVerified: (state, action) => {
      state.isPhoneVerified = action.payload;
    },
  },
});

export const {
  addUser,
  logout,
  setRememberMe,
  setForgetEmail,
  setOldUser,
  updateUserData,
  setEmailVerified,
  setPhoneVerified,
} = userSlice.actions;
export default userSlice.reducer;
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import BackToTop from './components/BackToTop';
import PartnerWithUs from './pages/PartnerWithUs';
import HowItWorks from './pages/HowItWorks';
import Pricing from './pages/Pricing';
import ComingSoon from './pages/ComingSoon';
import Register from './pages/Register';

function App() {
  return (
    <div className="">
      <BackToTop />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-in-touch" element={<PartnerWithUs />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog" element={<RedirectToBlog />} />
          <Route path="/blog/*" element={<RedirectToBlog />} />
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

function RedirectToBlog() {
  const { pathname } = useLocation();
  const baseUrl = "https://strabl.io";

  useEffect(() => {
    if (pathname.includes(".html")) {
      window.location.href = `${baseUrl}${pathname}`;
    } else {
      // check if pathname ends with slash
      if (pathname.endsWith("/")) {
        window.location.href = `${baseUrl}${pathname}index.html`;
      } else {
        window.location.href = `${baseUrl}${pathname}/index.html`;
      }
    }
  }, [pathname]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <h1 className="text-lg font-kineticaBold animate-pulse">
        Redirecting to blog...
      </h1>
    </div>
  );
}
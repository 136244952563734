import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

type Props = {
  name: string;
  label: string;
  options: string[];
  noOptionLabel?: string;
  onChange: (selectedOption: string) => void;
  selectedOption?: string;
  isNotSelected: boolean;
};

export default function DropDown({
  label,
  options,
  selectedOption,
  noOptionLabel,
  isNotSelected,
  onChange,
}: Props) {
  // State for Dropdown menu
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // Hide Dropdown Menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showMenu &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div className="relative w-full" ref={menuRef}>
      <button
        type="button"
        className={`relative flex justify-between items-center w-full border rounded-[4px] px-3 py-4 outline-none text-base font-gtAmerica text-granite-gray appearance-none bg-white autofill:shadow-white-inset
            ${isNotSelected
            ? "border-primary-red text-primary-red"
            : "border-black/25 text-black/60"
          }
            `}
        onClick={() => setShowMenu(!showMenu)}
      >
        {selectedOption ? selectedOption : label}
        <FiChevronDown className="text-[#292D32] text-xl" />
        <span
          className={`absolute left-2 px-1 bg-white transition ease-in-out duration-150 text-xs font-gtAmericaMedium text-granite-gray ${selectedOption ? "-translate-y-[1.6rem] opacity-100" : "opacity-0"
            }`}
        >
          {label}
        </span>
      </button>
      {showMenu && (
        <div className="absolute top-[90%] z-50 divide-y-[1px] bg-white rounded-b-[4px] border-black/[23%] border-[1px] outline-none text-base font-normal text-granite-gray px-4 py-3 w-full max-h-72 mt-1 overflow-scroll">
          {options.map((option, index) => (
            <div key={index} className="flex items-center gap-2 py-2">
              <button
                type="button"
                className="w-full text-left"
                onClick={() => {
                  setShowMenu(false);
                  onChange(option);
                }}
              >
                {option}
              </button>
            </div>
          ))}
          {
            options.length === 0 && (
              <div className="flex items-center gap-2 py-2">
                <button
                  type="button"
                  className="w-full text-left"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  {noOptionLabel ? noOptionLabel : "No Option Available"}
                </button>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
}

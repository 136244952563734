import React, { useState, useEffect } from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import OTPInput from "react-otp-input";
import axiosAPI from "../../utils/axiosAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearAllState,
  setCurrentStep,
} from "../../redux/slices/vendorRegisterRedux";
import excitedEmoji from "../../images/excitedEmoji.svg";
import PhoneInput from "react-phone-input-2";
import { updateUserData } from "../../redux/slices/userRedux";

export default function OtpVerification() {
  // Variables to handle Url navigation & Search Params
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // user Data with profile and Auth from Redux Store
  const user = useSelector((state: any) => state.user.userData);

  // State to Show Edit Contact Option
  const [editContact, setEditContact] = useState(false);

  // State to store Phone No for Edit
  const [phoneNo, setPhoneNo] = useState("");

  // State to show validation error message
  const [isPhoneNoError, setIsPhoneNoError] = useState(false);

  // State to Check if Phone No Input Got Changed
  const [isPhoneNoGotChanged, setIsPhoneNoGotChanged] = useState(false);

  // State to show Loading indicator while calling API
  const [isUpdating, setIsUpdating] = useState(false);

  // State for Otp Input
  const [otp, setOtp] = useState("");
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  // State to show error message
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [apiError, setApiError] = useState("");

  // States to show timer
  const [showResendTimer, setShowResendTimer] = useState(false);
  const [timerDuration, setTimerDuration] = useState(120);

  // States to handle resend Otp Attempts
  const [attempts, setAttempts] = useState(3);

  // Function to Send Otp to Phone
  const sendOptToPhone = () => {
    if (attempts !== 0) {
      axiosAPI
        .post(
          "/v1/public/api/user/send-otp",
          {
            email: user?.profile?.email,
            phoneNumber: user?.profile?.phoneNumber,
            mode: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .catch((error) => {
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      setApiError("Send OTP limits exceeds. Try after a while");
    }
  };

  // Function to handle verify Opt and Validation to check if OPT is entered or not
  const verifyOpt = () => {
    if (otp && otp.length === 6) {
      setIsVerifyingOtp(true);
      axiosAPI
        .post("/v1/public/api/user/verify-otp", {
          email: user?.profile?.email,
          phoneNumber: user?.profile?.phoneNumber,
          otp: otp,
          mode: 1,
        })
        .then((res) => {
          setIsVerifyingOtp(false);
          if (res?.data?.data) {
            navigate("/");
            dispatch(setCurrentStep(1));
            dispatch(clearAllState());
          }
        })
        .catch((error) => {
          setIsVerifyingOtp(false);
          setShowErrorMessage(true);
        });
    } else {
      setShowErrorMessage(true);
    }
  };

  const updatePhoneNo = () => {
    if (phoneNo && phoneNo?.length === 15) {
      if (!isPhoneNoGotChanged) {
        return;
      }
      setIsUpdating(true);
      axiosAPI
        .post(
          "/v1/api/user/edit-profile",
          {
            firstname: user.profile.firstName,
            lastname: user.profile.lastName,
            email: user.profile.email,
            contactNumber: phoneNo,
          },
          {
            headers: {
              Authorization: `Bearer ${user.auth.token}`,
            },
          }
        )
        .then((res) => {
          dispatch(updateUserData(res.data.data));
          setIsUpdating(false);
          setEditContact(false);
          // wait for 200ms second and then send otp
          setTimeout(() => {
            sendOptToPhone();
          }, 200);
        })
        .catch((error) => {
          setIsUpdating(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check if Phone Number is entered or not and is it valid or not
      if (!phoneNo || phoneNo?.length < 15) {
        setIsPhoneNoError(true);
      }
    }
  };

  // function to formate time from seconds to minutes & seconds 00:00 format
  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  // Start Timer when ever user clicks resend Otp
  useEffect(() => {
    let countdown: any;
    if (showResendTimer && timerDuration > 0) {
      countdown = setInterval(() => {
        setTimerDuration((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [showResendTimer, timerDuration]);

  // Reset TimeDuration when timer hits to 0
  useEffect(() => {
    if (timerDuration === 0) {
      setShowResendTimer(false);
      setTimerDuration(120);
    }
  }, [timerDuration]);

  // Send Otp to phone when page opens
  useEffect(() => {
    sendOptToPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide API Error when user reenter OTP
  useEffect(() => {
    setApiError("");
  }, [otp, editContact, phoneNo]);

  return !editContact ? (
    <div className="w-full">
      <img src={excitedEmoji} alt="" />

      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        COMPLETED
      </h1>
      <p className="text-base sm:text-xl 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3 flex items-center gap-x-1 w-full 2xl:w-10/12">
        Just one last thing enter the secret key we just sent on{" "}
        {user?.profile?.phoneNumber} to unlock more sales.
        <br /> Yes the OTP!!
      </p>
      <div className="mt-5 w-fit">
        <OTPInput
          value={otp}
          onChange={(e) => {
            setOtp(e);
            setShowErrorMessage(false);
          }}
          numInputs={6}
          renderSeparator={<span>&nbsp;&nbsp;</span>}
          //   isInputNum
          shouldAutoFocus
          inputType="number"
          inputStyle={{
            width: "100%",
            height: "70px",
            border: `1px solid ${showErrorMessage ? "#FF485C" : "#D9D9D9"}`,
            borderRadius: "6px",
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "21px",
            color: `${showErrorMessage ? "#FF485C" : "#3A3A3A"}`,
          }}
          renderInput={(props) => <input {...props} />}
        //   className="h-[50px] sm:h-[60px] md:h-[70px]"
        />
        {showErrorMessage && (
          <div className="flex justify-between items-center mt-4">
            <p className="flex items-center text-primary-red font-gtAmericaMedium text-sm">
              <BsFillExclamationCircleFill className="mr-2" />
              Invalid OTP, Try Again
            </p>
            <p className="text-primary-red font-gtAmericaMedium text-sm">
              {attempts} Attempts Left
            </p>
          </div>
        )}

        <div className="mt-5 flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row gap-y-5 justify-between items-start sm:items-center md:items-start lg:items-center">
          <div className="flex justify-between sm:justify-start items-center gap-x-2.5 w-full sm:w-auto md:w-full lg:w-auto">
            <button
              className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:bg-[#cccccc] disabled:text-[#666666] disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
              type="button"
              onClick={verifyOpt}
              disabled={isVerifyingOtp}
            >
              {isVerifyingOtp ? "Verifying" : "FINISH"}
            </button>
            <button
              className="bg-white text-smoky-black text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
              onClick={() => {
                setPhoneNo(user?.profile?.phoneNumber);
                setEditContact(true);
              }}
              type="button"
              disabled={isVerifyingOtp}
            >
              Edit contact no#
            </button>
          </div>

          {showResendTimer ? (
            <p className="w-fit	font-gtAmericaMedium text-[12px] sm:text-sm text-smoky-black">
              <span className="underline">Resend OTP</span> in{" "}
              {formatTime(timerDuration)}
            </p>
          ) : (
            <button
              className="bg-transparent text-smoky-black text-[15px] font-gtAmericaMedium underline disabled:text-[#666666] disabled:cursor-not-allowed transition-all duration-150 ease-in-out"
              type="button"
              onClick={() => {
                setShowResendTimer(true);
                setAttempts(attempts - 1);
                sendOptToPhone();
              }}
              disabled={attempts === 0}
            >
              Resend OTP
            </button>
          )}
        </div>
        {apiError && (
          <p className="mt-4 w-96 text-base text-primary-red font-gtAmerica">
            {apiError}
          </p>
        )}
      </div>
    </div>
  ) : (
    <div className="w-full">
      <h1 className="text-3xl sm:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase tracking-tight my-3">
        Edit Contact No
      </h1>
      <p className="text-base sm:text-xl 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-3 flex items-center gap-x-1 w-full 2xl:w-10/12">
        Edit your contact no#
      </p>
      <div className="mt-5 w-full">
        <div className="relative w-full">
          <PhoneInput
            inputProps={{
              name: "contactNo",
              required: true,
            }}
            inputStyle={{
              width: "100%",
              color: `${isPhoneNoError ? "#ff485c" : "black"}`,
              border: `1px solid ${isPhoneNoError ? "#ff485c" : "#d1d5db"}`,
            }}
            buttonStyle={{
              border: `1px solid ${isPhoneNoError ? "#ff485c" : "#d1d5db"}`,
            }}
            country={"ae"}
            onlyCountries={["ae", "sa"]}
            placeholder="Contact No#"
            countryCodeEditable={false}
            value={phoneNo || ""}
            onChange={(phoneNumber) => {
              setPhoneNo(phoneNumber);
              setIsPhoneNoError(false);
              setIsPhoneNoGotChanged(true);
            }}
            inputClass={`rounded-[4px] px-3 py-7 text-sm text-gray-900 bg-white appearance-none focus:outline-none focus:ring-0 peer`}
          />
        </div>
        <div className="mt-4 col-span-full">
          <div className="flex justify-start items-center gap-x-2.5">
            <button
              className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded border border-black shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:bg-[#cccccc] disabled:text-[#666666] disabled:cursor-not-allowed disabled:shadow-none disabled:border-0 transition-all duration-150 ease-in-out"
              type="button"
              disabled={isUpdating || !isPhoneNoGotChanged}
              onClick={() => {
                updatePhoneNo();
              }}
            >
              {isUpdating ? "Updating..." : "Update"}
            </button>
            <button
              className="bg-white text-smoky-black text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:shadow-none disabled:bg-smoky-black disabled:opacity-30 disabled:text-white disabled:cursor-not-allowed border border-black transition-all duration-150 ease-in-out"
              type="button"
              disabled={isUpdating}
              onClick={() => {
                setEditContact(false);
                setPhoneNo("");
                setIsPhoneNoGotChanged(false);
              }}
            >
              Cancel
            </button>
          </div>
          {apiError && (
            <p className="mt-4 text-sm sm:text-base text-primary-red font-gtAmerica">
              {apiError}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

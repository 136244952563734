import React, { useEffect, useState } from "react";
import strablLogo from "../images/strablLogo.svg";
import { useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import blueCurvedLine2 from "../images/svgPaths/blueCurvedLine2.svg";

export default function Header() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <header className="sticky top-0 z-20 bg-[#02D6E0] lg:bg-white px-7 lg:px-24 grid grid-cols-12 gap-1 py-4 border-b-4 border-black">
            <div className="col-span-12 flex justify-between items-center ">
                <div
                    className="justify-self-center order-2 lg:order-1 cursor-pointer w-32 border-2 border-black px-2 py-1 drop-shadow-[4px_4px_0_rgba(0,0,0,1)] bg-white"
                    onClick={() => navigate("/")}
                >
                    <img src={strablLogo} width={836} height={233} alt="Strabl logo" />
                </div>
                <nav className="order-1 lg:order-2">
                    <ul
                        className={` ${isMenuOpen
                            ? "w-screen lg:w-auto h-screen lg:h-auto fixed lg:static top-0 left-0 flex"
                            : "hidden lg:flex"
                            } flex-col lg:flex-row justify-start lg:justify-end items-start lg:items-center gap-5 bg-[#FF485C] lg:bg-transparent px-8 lg:px-0 pt-28 lg:pt-0`}
                    >
                        <li
                            className="text-2xl block lg:hidden lg:text-[12px] font-kineticaBold cursor-pointer text-white lg:text-black z-[1] lg:z-auto"
                            onClick={() => {
                                navigate("/");
                                setIsMenuOpen(false);
                            }}
                        >
                            Home
                        </li>
                        <li
                            className="text-2xl lg:text-[12px] font-kineticaBold cursor-pointer text-white lg:text-black z-[1] lg:z-auto"
                            onClick={() => {
                                navigate("/how-it-works");
                                setIsMenuOpen(false);
                            }}
                        >
                            How it works
                        </li>
                        <li
                            className="text-2xl lg:text-[12px] font-kineticaBold cursor-pointer text-white lg:text-black z-[1] lg:z-auto"
                            onClick={() => {
                                navigate("/pricing");
                                setIsMenuOpen(false);
                            }}
                        >
                            Pricing
                        </li>
                        <li className="z-[1] lg:z-auto">
                            <button
                                className="text-2xl lg:text-[12px] px-0 lg:px-6 py-0 lg:py-1 font-kineticaBold border-black border-0 lg:border-2 rounded-lg text-white lg:text-black z-[1] lg:z-auto"
                                onClick={() => {
                                    navigate("/get-in-touch");
                                    setIsMenuOpen(false);
                                }}
                            >
                                Get In Touch
                            </button>
                        </li>
                        <li className="z-[1] lg:z-auto">
                            <button
                                className="text-2xl lg:text-[12px] px-0 lg:px-6 py-0 lg:py-1 font-kineticaBold border-black border-0 lg:border-2 rounded-lg bg-transparent lg:bg-[#02D6E0] drop-shadow-none lg:drop-shadow-[2px_2px_0_rgba(0,0,0,1)] text-white lg:text-black z-[1] lg:z-auto"
                                onClick={() => {
                                    // navigate("/login");
                                    setIsMenuOpen(false);
                                }}
                            >
                                <a href="https://strabl.com/login">Login</a>
                            </button>
                        </li>
                        <IoClose
                            className="text-5xl block lg:hidden cursor-pointer text-white absolute top-7 right-7 z-[2] lg:z-auto"
                            onClick={() => setIsMenuOpen(false)}
                        />
                        <img
                            src={blueCurvedLine2}
                            alt="blue curved line"
                            className="absolute bottom-0 left-0 block lg:hidden w-full z-0"
                        />
                    </ul>
                    <HiMenu
                        className="text-4xl block lg:hidden cursor-pointer"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    />
                </nav>
                <div className="order-3 block lg:hidden">
                    <HiMenu className="text-4xl block lg:hidden opacity-0" />
                </div>
            </div>
        </header>
    );
}
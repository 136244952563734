import React, { ChangeEvent, ReactNode } from "react";

type Props = {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  value?: string | number;
  hasIcon: boolean;
  icon?: ReactNode;
  hasPostFix?: boolean | false;
  postFix?: string | "";
  showError?: boolean | false;
  minLength?: number;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function Input({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  minLength,
  showError,
  hasPostFix,
  postFix,
}: Props) {

  // Styles for Floating Label style
  const floatLableStyle =
    "absolute left-2 -translate-y-7 peer-focus:-translate-y-7 peer-placeholder-shown:translate-y-0 px-1 bg-white transition ease-in-out duration-150 text-xs peer-focus:text-xs peer-placeholder-shown:text-sm font-gtAmericaMedium peer-focus:font-gtAmericaMedium peer-placeholder-shown:font-gtAmerica text-granite-gray peer-placeholder-shown:text-black/60 peer-focus:text-granite-gray";

  const floatLableErrorStyle =
    "absolute left-2 -translate-y-7 peer-focus:-translate-y-7 peer-placeholder-shown:translate-y-0 px-1 bg-white transition ease-in-out duration-150 text-xs peer-focus:text-xs peer-placeholder-shown:text-sm font-gtAmericaMedium peer-focus:font-gtAmericaMedium peer-placeholder-shown:font-gtAmerica text-primary-red peer-placeholder-shown:text-primary-red peer-focus:text-primary-red";

  return (
    <label htmlFor={name} className="w-full relative flex items-center">
      <input
        type={type}
        className={`peer placeholder:text-transparent w-full border rounded-[4px] px-3 py-4 outline-none text-base font-gtAmerica text-black/60 appearance-none bg-white autofill:shadow-white-inset [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-cancel-button]:appearance-none ${showError
            ? "border-red-500"
            : "border-black/[23%] focus:border-smoky-black"
          }`}
        name={name}
        id={name}
        placeholder={placeholder}
        maxLength={maxLength ? maxLength : 100}
        minLength={minLength ? minLength : 0}
        value={value}
        onChange={onChange}
      />
      <span className={showError ? floatLableErrorStyle : floatLableStyle}>
        {label}
      </span>
      {hasPostFix && (
        <span className="absolute right-3 text-base font-gtAmerica text-dashboard-menu bg-white">
          {postFix}
        </span>
      )}
    </label>
  );
}

import { Autocomplete } from "@react-google-maps/api";
import React, { useState } from "react";
import Input from "./Input";

export default function GoogleSearchAutoComplete({
  onChange,
  onSelect,
  showError,
  inputValue,
  isLoaded,
}) {
  // State to Store Search Results
  const [searchResult, setSearchResult] = useState();

  // Options for Search suggestions
  const searchOptions = {
    fields: ["address_components", "geometry", "name"],
    componentRestrictions: { country: ["ae", "sa"] },
  };

  // handle When user clicks on a result
  const onPlaceChanged = () => {
    if (searchResult !== null) {
      const place = searchResult.getPlace();
      const addressComponents = place.address_components;

      const country = addressComponents?.find((component) =>
        component.types.includes("country")
      )?.long_name;

      const city = addressComponents?.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name;

      const area = addressComponents?.find((component) =>
        component.types.includes("neighborhood")
      )?.short_name;

      const address = place?.name;

      const latitude = place?.geometry?.location.lat();
      const longitude = place?.geometry?.location.lng();

      onSelect({
        country: country ? country : "",
        city: city ? city : "",
        area: area ? area : "",
        address: address ? address : "",
        latitude: latitude ? latitude : 25.184672972293185,
        longitude: longitude ? longitude : 55.29787416989978,
      });
    }
  };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  return (
    isLoaded && (
      <Autocomplete
        onPlaceChanged={onPlaceChanged}
        onLoad={onLoad}
        options={searchOptions}
        className="~appearance-none ~focus:outline-none focus:ring-4 focus:ring-red-600 peer"
      >
        <Input
          hasIcon={false}
          label="Address"
          name="address"
          placeholder="Address"
          value={inputValue}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          type="text"
          hasPostFix={false}
          key="address"
          showError={showError}
        />
      </Autocomplete>
    )
  );
}

import React, { useState, useEffect } from "react";
import Input from "../Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axiosAPI from "../../utils/axiosAPI";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/slices/userRedux";
import wavingHandEmoji from "../../images/wavingHandEmoji.svg";
import { setContactDetails } from "../../redux/slices/vendorRegisterRedux";
import { useNavigate } from "react-router-dom";

type Props = {
  goNext: () => void;
};

export default function ContactDetails({ goNext }: Props) {
  // Variables to handle Url navigation & Search Params
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // Already Filled Inputs Data from Redux Store
  const contactData = useSelector(
    (state: any) => state.vendorRegister.contactDetails
  );
  // State to Check if Any Inputs Got Changed
  const [isAnyInputGotChanged, setIsAnyInputGotChanged] = useState(false);

  // States for Inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  // States for Validation Errors
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneNoError, setIsPhoneNoError] = useState(false);

  // State to show Loading indicator while calling API
  const [isRegistering, setIsRegistering] = useState(false);

  // State to show Error Response from API
  const [apiError, setApiError] = useState("");

  // Check for all required fields and go next
  const clickRegister = () => {
    // Check if all required fields are entered then go next
    if (
      firstName &&
      lastName &&
      email &&
      emailRegex.test(email) &&
      phoneNo &&
      phoneNo?.length === 15
    ) {
      setIsRegistering(true);
      // Check if Nothing got Changed then Go next
      if (!isAnyInputGotChanged) {
        goNext();
        return;
      }

      axiosAPI
        .post("/auth/register", {
          email: email,
          password: "abc123",
          firstName: firstName,
          lastName: lastName,
          phoneNumber: `+${phoneNo}`,
          profilePicture: "https://picsum.photos/400",
          userPlatform: 3,
        })
        .then((res) => {
          dispatch(addUser(res.data.data));
          dispatch(
            setContactDetails({
              firstName: firstName,
              lastName: lastName,
              email: email,
              phoneNumber: phoneNo,
            })
          );
          setIsRegistering(false);
          goNext();
        })
        .catch((error) => {
          setIsRegistering(false);
          setApiError(
            error.response.data.message || "Something went wrong. Try Again!"
          );
        });
    } else {
      // Check if First Name is entered or not
      if (!firstName) {
        setIsFirstNameError(true);
      }

      // Check if Last Name is entered or not
      if (!lastName) {
        setIsLastNameError(true);
      }

      // Check if Email is entered or not and is it valid or not
      if (!email || !emailRegex.test(email)) {
        setIsEmailError(true);
      }

      // Check if Phone Number is entered or not and is it valid or not
      if (!phoneNo || phoneNo?.length < 15) {
        setIsPhoneNoError(true);
      }
    }
  };

  useEffect(() => {
    setApiError("");
  }, [firstName, lastName, email, phoneNo]);

  useEffect(() => {
    console.log("contactData", contactData);
    setFirstName(contactData.firstName);
    setLastName(contactData.lastName);
    setEmail(contactData.email);
    setPhoneNo(contactData.phoneNumber);
  }, [contactData]);

  return (
    <div className="w-full mt-2 md:mt-0">
      <div className="flex items-center gap-x-0.5">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl font-gtAmericaMedium text-smoky-black uppercase">
          Welcome
        </h1>
        <img
          src={wavingHandEmoji}
          alt="Waving Hand Emoji"
          className="w-10 -mt-3 block md:hidden"
        />
      </div>
      <p className="text-sm sm:text-lg md:text-base 2xl:text-xl font-gtAmericaMedium text-smoky-black mt-4 lg:mt-3">
        We're excited to have you on board! Let's make the magic happen!
      </p>
      <form
        className="grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-3 mt-5 lg:mt-6"
        onSubmit={(e) => {
          e.preventDefault();
          clickRegister();
        }}
      >
        <Input
          name="firstName"
          label="First Name"
          hasIcon={false}
          placeholder="First Name"
          type="text"
          key="firstName"
          maxLength={100}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setIsFirstNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isFirstNameError}
        />
        <Input
          name="lastName"
          label="Last Name"
          hasIcon={false}
          placeholder="Last Name"
          type="text"
          key="lastName"
          maxLength={100}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setIsLastNameError(false);
            setIsAnyInputGotChanged(true);
          }}
          showError={isLastNameError}
        />
        <div className="col-span-1 lg:col-span-2">
          <Input
            name="email"
            label="Email"
            hasIcon={false}
            placeholder="Email"
            type="email"
            key="email"
            maxLength={100}
            value={email}
            onChange={(e) => {
              if (emailRegex.test(e.target.value)) {
                setIsEmailError(false);
              } else {
                setIsEmailError(true);
              }
              setEmail(e.target.value);
              setIsAnyInputGotChanged(true);
            }}
            showError={isEmailError}
          />
        </div>

        <div className="relative w-full col-span-1 lg:col-span-2">
          <PhoneInput
            inputProps={{
              name: "contactNo",
              required: true,
            }}
            inputStyle={{
              width: "100%",
              color: `${isPhoneNoError ? "#ff485c" : "black"}`,
              border: `1px solid ${isPhoneNoError ? "#ff485c" : "#d1d5db"}`,
            }}
            buttonStyle={{
              border: `1px solid ${isPhoneNoError ? "#ff485c" : "#d1d5db"}`,
            }}
            country={"ae"}
            onlyCountries={["ae", "sa"]}
            placeholder="Contact No#"
            countryCodeEditable={false}
            value={phoneNo || ""}
            onChange={(phoneNumber) => {
              setPhoneNo(phoneNumber);
              setIsPhoneNoError(false);
              setIsAnyInputGotChanged(true);
            }}
            inputClass={`rounded-[4px] px-3 py-7 text-sm text-gray-900 bg-white appearance-none focus:outline-none focus:ring-0 peer`}
          />
        </div>
        <div className="mt-4 col-span-full">
          <div className="flex justify-between items-center">
            <button
              className="bg-tertiary-blue hover:bg-tertiary-blue-dark text-white text-[15px] font-gtAmericaMedium px-4 pt-4 pb-3 uppercase rounded border border-black shadow-[2px_2px_0_black] hover:shadow-[3px_4px_0_black] disabled:bg-[#cccccc] disabled:text-[#666666] disabled:cursor-not-allowed disabled:shadow-none disabled:border-0 transition-all duration-150 ease-in-out"
              type="submit"
              disabled={isRegistering}
            >
              {isRegistering ? "Registering..." : "Continue"}
            </button>
            <button
              className="text-sm font-gtAmericaMedium text-smoky-black bg-transparent"
              type="button"
              onClick={() => {
                navigate("/");
              }}
              disabled={isRegistering}
            >
              Back
            </button>
          </div>
          {apiError && (
            <p className="mt-4 text-sm sm:text-base text-primary-red font-gtAmerica">
              {apiError}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

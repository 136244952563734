import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type Props = {
  lat: number | 25.184672972293185;
  lng: number | 55.29787416989978;
  onSelect: (lat: number, lng: number) => void;
  isLoaded: boolean;
  showSearch: boolean;
};

export default function SelectMapLocation({ lat, lng, onSelect, isLoaded, showSearch }: Props) {

  const [markerLocation, setMarkerLocation] = useState({
    lat: lat,
    lng: lng,
  });

  const searchBoxRef = useRef<any>();

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const options = {};

  function setMapLocation(e: any) {
    setMarkerLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    onSelect(e.latLng.lat(), e.latLng.lng());
  }
  useEffect(() => {
    setMarkerLocation({
      lat: lat,
      lng: lng,
    });
  }, [lat, lng]);

  if (!isLoaded) {
    return (
      <div className=" w-full flex justify-center items-center">
        <p className="text-4xl text-secondary-blue animate-spin ">
          <AiOutlineLoading3Quarters />
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerLocation}
          zoom={15}
          onClick={(e) => {
            setMapLocation(e);
          }}
          options={options}
        >
          <Marker
            draggable={true}
            position={markerLocation}
            onDragEnd={(e) => {
              setMapLocation(e);
            }}
          />
          {showSearch && <StandaloneSearchBox
            onLoad={(searchBox) => {
              searchBoxRef.current = searchBox;
            }}
            onPlacesChanged={() => {
              const places = searchBoxRef.current.getPlaces();
              const bounds = new window.google.maps.LatLngBounds();
              places.forEach((place: any) => {
                if (place.geometry.viewport) {
                  bounds.union(place.geometry.viewport);
                } else {
                  bounds.extend(place.geometry.location);
                }
              });
              const nextMarkers = places.map((place: any) => ({
                position: place.geometry.location,
              }));
              const nextCenter = nextMarkers.length
                ? nextMarkers[0].position
                : markerLocation;
              setMarkerLocation(nextCenter);
              setMapLocation({
                latLng: {
                  lat: () => nextCenter.lat(),
                  lng: () => nextCenter.lng(),
                },
              });
            }}
          >
            <input
              type="text"
              placeholder="Search for location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid #3A3A3A`,
                width: `300px`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `7px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `15px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                top: "10px",
                left: "1%",
                color: "#3A3A3A",
              }}
            />
          </StandaloneSearchBox>
          }
        </GoogleMap>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import BrandCardForHome from "./cards/BrandCardForHome";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";
import brandsFolderStyleBg from "../images/svgPaths/brandsFolderStyleBg.svg";
import brandsFolderStyleYellowBg from "../images/svgPaths/brandsFolderStyleYellowBg.svg";
import deeeLine from "../images/brandLogos/deeeLine.png";
import baruni from "../images/brandLogos/baruni.png";
import janaraJones from "../images/brandLogos/janaraJones.png";
import janinaJonee from "../images/brandLogos/janinaJonee.png";
import papayarosa from "../images/brandLogos/papayarosa.png";
import stoneGrey from "../images/brandLogos/stoneGrey.png";
import sweetyAbaya from "../images/brandLogos/sweetyAbaya.png";
import trentasette from "../images/brandLogos/trentasette.png";

export default function BrandsSlider() {
  const [width, setWidth] = useState(window.innerWidth);
  const brands = [
    {
      title: "Papayarosa",
      imageUrl: papayarosa,
    },
    {
      title: "Trentasette",
      imageUrl: trentasette,
    },
    {
      title: "Sweety Abaya",
      imageUrl: sweetyAbaya,
    },
    {
      title: "Stone Grey",
      imageUrl: stoneGrey,
    },
    {
      title: "Janina Jonee",
      imageUrl: janinaJonee,
    },
    {
      title: "Baruni Fashion",
      imageUrl: baruni,
    },
    {
      title: "Janara Jones",
      imageUrl: janaraJones,
    },
    {
      title: "Deee Line",
      imageUrl: deeeLine,
    },
  ];

  useEffect(() => {
    document.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      document.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <section className="border-black bg-[#EBF0FF] border-t-4 mt-32 pl-5 md:pl-24 2xl:pl-32 flex flex-col md:flex-row items-center justify-center md:justify-start gap-4 md:gap-8 max-w-[100vw]">
      <div className="relative flex justify-start items-center pt-16 pb-5 md:pt-20 md:pb-20">
        <h2 className="absolute top-2/12 bottom-2/12 left-4 my-auto font-kineticaBold text-3xl md:text-2xl xl:text-3xl text-black md:text-white w-56 md:w-48 xl:w-56 tracking-tight drop-shadow-none">
          Brands that trust us
        </h2>
        <img
          src={brandsFolderStyleBg}
          alt="Brands Folder Style Bg"
          className="hidden md:block w-64 md:w-52  xl:w-64"
        />
        <img
          src={brandsFolderStyleYellowBg}
          alt="Brands Folder Style Bg"
          className="block md:hidden w-64 md:w-52  xl:w-64"
        />
      </div>
      <section className="border-black border-l-0 md:border-l-4 grow w-full md:w-6/12 px-5 sm:px-10 py-10 ">
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          slidesPerGroup={3}
          centeredSlides={false}
          speed={1000}
          pagination={{
            clickable: true,
          }}
          autoplay={true}
          navigation={width < 640 ? false : true}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            850: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          loop={false}
          modules={[Autoplay, Navigation, Pagination]}
          className="brands-slide"
        >
          {brands.map((brand, index) => (
            <SwiperSlide key={index}>
              <BrandCardForHome name={brand.title} image={brand.imageUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </section>
  );
}
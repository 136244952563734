import React, { useState, useEffect } from 'react'
import { BsFillArrowUpCircleFill } from "react-icons/bs"

export default function BackToTop() {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <button
            className={`fixed bottom-8 right-8 text-3xl text-[#FF485C] z-10 p-0 w-fit bg-white rounded-full drop-shadow-lg ${isVisible ? "opacity-100 scale-100" : "opacity-0 scale-0"} transition-all ease-in duration-300`}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            title="Back to top"
        >
            <BsFillArrowUpCircleFill className="scale-105" />
        </button>
    )
}
import React, { useState } from "react";
import underline2 from "../images/svgPaths/underline2.svg";
import { ImCheckmark } from "react-icons/im";
import crown from "../images/svgPaths/crown.svg";
import partnerWithUsIllustration from "../images/partnerWithUsIllustration.png";
import star from "../images/svgPaths/star.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import shopify from "../images/brandLogos/shopify.png";
import wooCommerce from "../images/brandLogos/wooCommerce.png";
import salla from "../images/brandLogos/salla.png";
import openCart from "../images/brandLogos/openCart.png";
import appZid from "../images/brandLogos/app-zid.png";
import rightToLeftArrow from "../images/svgPaths/rightToLeftArrow.svg";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pricing() {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const basicPlan = {
    title: "Basic",
    price: "$0",
    features: [
      "Upto 50 orders a month",
      "7% transaction fee",
      "Email Support",
      "Single user",
    ],
  };
  const starterPlan = {
    title: "STARTER",
    price: "$49",
    features: [
      "Upto 50 orders a month",
      "4.5% transaction fee",
      "Email Support",
      "Single user",
    ],
  };
  const standardPlan = {
    title: "Standard",
    price: "$79",
    features: [
      "Upto 500 orders a month",
      "4% transaction fee",
      "Priority Support",
      "Upto 4 users",
      "Select products for TNBL",
      "Marketing Tools",
    ],
  };
  const enterprisePlan = {
    title: "Enterprise",
    price: "Contact for pricing",
    features: [
      "Unlimited orders",
      "*4% transaction fee",
      "Priority Support",
      "Upto 20 users",
      "Select products for TNBL",
      "Marketing Tools",
    ],
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <>
      <Header />
      <main>
        <section className="flex flex-col justify-center items-center pt-20 pb-3 lg:pb-14 px-5 sm:px-10 md:px-20 lg:px-32 xl:px-60 gap-5 md:gap-10">
          <h1 className="font-gtAmericaBold  md:font-kineticaBold text-2xl md:text-4xl text-center text-black leading-[39px] md:leading-[65px] normal-case md:uppercase">
            Ready to get started <br className="hidden md:block" /> with{" "}
            <br className="block md:hidden" />
            <span className="text-[#FF485C] relative inline-block font-kineticaBold text-5xl md:text-4xl">
              STRABL
              <img
                src={underline2}
                alt="underline"
                className="w-full absolute -bottom-8 hidden md:block "
              />
            </span>
          </h1>
        </section>
        {width > 1245 ? (
          <section className="px-12 2xl:px-20 mt-5 mb-16 grid grid-cols-4 gap-4 xl:gap-4">
            <section className="my-12 max-w-md ~min-w-max border-[4px] border-black bg-white rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
              <h1 className="bg-black border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                {basicPlan.title}
              </h1>
              <h2 className="font-gtAmericaBold text-4xl text-black">
                {basicPlan.price}/<span className="text-xl">Month</span>
              </h2>
              <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                {basicPlan.features.map((feature, index) => (
                  <li
                    key={index}
                    className="font-gtAmerica text-base flex justify-between items-center"
                  >
                    {feature}
                    <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                  </li>
                ))}
              </ul>
              <button
                className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                onClick={() => {
                  navigate("/get-in-touch");
                }}
              >
                Get Now
              </button>
            </section>
            <section className="my-12 max-w-md ~min-w-max border-[4px] border-black bg-white rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
              <h1 className="bg-black border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                {starterPlan.title}
              </h1>
              <h2 className="font-gtAmericaBold text-4xl text-black">
                {starterPlan.price}/<span className="text-xl">Month</span>
              </h2>
              <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                {starterPlan.features.map((feature, index) => (
                  <li
                    key={index}
                    className="font-gtAmerica text-base flex justify-between items-center"
                  >
                    {feature}
                    <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                  </li>
                ))}
              </ul>
              <button
                className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                onClick={() => {
                  navigate("/get-in-touch");
                }}
              >
                Get Now
              </button>
            </section>
            <section className="my-12 translate-y-1 scale-y-[1.02] max-w-md ~min-w-max border-[5px] border-black bg-[#B103EE] rounded-3xl px-8 pt-10 pb-6 relative flex flex-col shadow-[3px_6px_0px_0_rgba(0,0,0,1)]">
              <div className="absolute bottom-full">
                <h1 className="bg-[#B103EE] border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase rounded-t-md">
                  {standardPlan.title}
                </h1>
                <div className="relative">
                  <img
                    src={crown}
                    alt="Crown"
                    className="absolute bottom-full -right-12 rotate-12 w-20 z-[1]"
                  />
                </div>
              </div>
              <h2 className="font-gtAmericaBold text-4xl text-white">
                {standardPlan.price}/<span className="text-xl">Month</span>
              </h2>
              <ul className="flex flex-col gap-y-3 my-5 text-white grow">
                {standardPlan.features.map((feature, index) => (
                  <li
                    key={index}
                    className="font-gtAmerica text-base flex justify-between items-center"
                  >
                    {feature}
                    <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                  </li>
                ))}
              </ul>
              <button
                className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                onClick={() => {
                  navigate("/get-in-touch");
                }}
              >
                Get Now
              </button>

              <div className="bg-transparent absolute -top-[0.2rem] -right-[0.2rem] rounded-3xl z-[0] ">
                <svg
                  width="102"
                  height="102"
                  viewBox="0 0 102 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 1.99976L100 96.9998V26.9998C100 13.1926 88.8071 1.99976 75 1.99976H5Z"
                    fill="white"
                    stroke="black"
                    stroke-width="4"
                  />
                </svg>

                <p className="font-gtAmericaBold text-sm absolute top-3 right-0 text-center rotate-45 uppercase">
                  Most <br /> Popular
                </p>
              </div>
            </section>
            <section className="my-12 max-w-md ~min-w-max border-[4px] border-black bg-white rounded-3xl px-8 pt-14 pb-6 relative flex flex-col">
              <h1 className="bg-[#FF485C] border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                {enterprisePlan.title}
              </h1>
              <h2 className="font-gtAmericaBold text-xl text-black">
                {enterprisePlan.price}
              </h2>
              <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                {enterprisePlan.features.map((feature, index) => (
                  <li
                    key={index}
                    className="font-gtAmerica text-base flex justify-between items-center"
                  >
                    {feature}
                    <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                  </li>
                ))}
              </ul>
              <button
                className="border-black border-2 bg-white rounded-lg block mx-auto mt-8 py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                onClick={() => {
                  navigate("/get-in-touch");
                }}
              >
                Get in touch
              </button>
            </section>
          </section>
        ) : (
          <section className=" px-1 sm:px-2 mt-6 sm:mt-10 mb-16">
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              speed={1000}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              navigation={width < 500 ? true : false}
              autoplay={true}
              breakpoints={{
                500: {
                  slidesPerView: 2,
                  spaceBetween: 150,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 100,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                850: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 70,
                },
              }}
              loop={true}
              modules={[Navigation, Pagination]}
              className="pricing-slide"
            >
              <SwiperSlide>
                <section className="my-12 h-[26rem] mx-auto max-w-[300px] sm:max-w-screen-sm min-w-max border-[5px] border-black bg-white rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
                  <h1 className="bg-black border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                    {basicPlan.title}
                  </h1>
                  <h2 className="font-gtAmericaBold text-4xl text-black">
                    {basicPlan.price}/<span className="text-xl">Month</span>
                  </h2>
                  <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                    {basicPlan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="font-gtAmerica text-xl flex justify-between items-center"
                      >
                        {feature}
                        <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                      </li>
                    ))}
                  </ul>
                  <button
                    className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                    onClick={() => {
                      navigate("/get-in-touch");
                    }}
                  >
                    Get Now
                  </button>
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="my-12 h-[26rem] mx-auto max-w-[300px] sm:max-w-screen-sm min-w-max border-[5px] border-black bg-white rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
                  <h1 className="bg-black border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                    {starterPlan.title}
                  </h1>
                  <h2 className="font-gtAmericaBold text-4xl text-black">
                    {starterPlan.price}/<span className="text-xl">Month</span>
                  </h2>
                  <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                    {starterPlan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="font-gtAmerica text-xl flex justify-between items-center"
                      >
                        {feature}
                        <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                      </li>
                    ))}
                  </ul>
                  <button
                    className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                    onClick={() => {
                      navigate("/get-in-touch");
                    }}
                  >
                    Get Now
                  </button>
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="my-12 h-[26rem] mx-auto max-w-[300px] sm:max-w-screen-sm min-w-max border-[5px] border-black bg-[#B103EE] rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
                  <div className="absolute bottom-full">
                    <h1 className="bg-[#B103EE] border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase rounded-t-md">
                      {standardPlan.title}
                    </h1>
                    <div className="relative">
                      <img
                        src={crown}
                        alt="Crown"
                        className="absolute bottom-full -right-12 rotate-12 w-20 z-[1]"
                      />
                    </div>
                  </div>

                  <h2 className="font-gtAmericaBold text-4xl text-white">
                    {standardPlan.price}/<span className="text-xl">Month</span>
                  </h2>
                  <ul className="flex flex-col gap-y-3 my-5 text-white grow">
                    {standardPlan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="font-gtAmerica text-xl flex justify-between items-center"
                      >
                        {feature}
                        <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                      </li>
                    ))}
                  </ul>
                  <button
                    className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                    onClick={() => {
                      navigate("/get-in-touch");
                    }}
                  >
                    Get Now
                  </button>
                  <div className="bg-transparent absolute -top-[0.26rem] -right-[0.26rem] rounded-3xl z-[0] ">
                    <svg
                      width="102"
                      height="102"
                      viewBox="0 0 102 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 1.99976L100 96.9998V26.9998C100 13.1926 88.8071 1.99976 75 1.99976H5Z"
                        fill="white"
                        stroke="black"
                        stroke-width="4"
                      />
                    </svg>

                    <p className="font-gtAmericaBold text-sm absolute top-3 right-0 text-center rotate-45 uppercase">
                      Most <br /> Popular
                    </p>
                  </div>
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="my-12 h-[26rem] mx-auto max-w-[300px] sm:max-w-screen-sm min-w-max border-[5px] border-black bg-white rounded-3xl px-8 pt-10 pb-6 relative flex flex-col">
                  <h1 className="bg-[#FF485C] border-black border-[5px] text-white text-sm font-kineticaBold w-fit px-5 py-1 uppercase absolute bottom-full rounded-t-md">
                    {enterprisePlan.title}
                  </h1>
                  <h2 className="font-gtAmericaBold text-xl text-black">
                    {enterprisePlan.price}
                  </h2>
                  <ul className="flex flex-col gap-y-3 my-5 text-black grow">
                    {enterprisePlan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="font-gtAmerica text-xl flex justify-between items-center"
                      >
                        {feature}
                        <ImCheckmark className="text-[#0DED4C] text-xl drop-shadow-[1px_2px_0_rgba(0,0,0,1)]" />
                      </li>
                    ))}
                  </ul>
                  <button
                    className="border-black border-2 bg-white rounded-lg block mx-auto py-2 px-6 font-kineticaBold text-xs text-black drop-shadow-[2px_2px_0_rgba(0,0,0,1)]"
                    onClick={() => {
                      navigate("/get-in-touch");
                    }}
                  >
                    Get in touch
                  </button>
                </section>
              </SwiperSlide>
            </Swiper>
          </section>
        )}

        <section className="bg-[#EBF0FF] py-12 sm:py-20 flex flex-col justify-center items-center gap-10 relative">
          <section className="w-fit flex flex-col items-center">
            <h3 className="text-xl font-kineticaBold text-black tracking-tight uppercase">
              Available on
            </h3>
            <div className="px-4 py-1 w-fit mt-1 bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={shopify}
                alt="Shopify"
                className="w-64"
                width={463}
                height={130}
              />
            </div>
          </section>
          <section className="w-fit flex flex-col items-center">
            <h3 className="text-xl font-kineticaBold text-black tracking-tight uppercase">
              Coming soon
            </h3>
            <div className="flex items-center justify-center gap-6 px-7 py-3 w-fit mt-1 bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={wooCommerce}
                alt="Woo Commerce"
                className="w-36 sm:w-44"
                width={362}
                height={75}
              />
              <img
                src={salla}
                alt="Salla"
                className="w-16 sm:w-20"
                width={186}
                height={100}
              />
              <img
                src={openCart}
                alt="Open Cart"
                className="w-44 hidden sm:block"
                width={374}
                height={79}
              />
              <img
                src={appZid}
                alt="App Zid"
                className="w-20 hidden sm:block"
                width={161}
                height={81}
              />
            </div>
            <div className="mt-5 flex sm:hidden items-center justify-center gap-6 px-7 py-3 w-fit bg-white border-2 border-black drop-shadow-[4px_4px_0_rgba(0,0,0,1)]">
              <img
                src={openCart}
                alt="Open Cart"
                className="w-36"
                width={374}
                height={79}
              />
              <img
                src={appZid}
                alt="App Zid"
                className="w-16"
                width={161}
                height={81}
              />
            </div>
          </section>
          <img
            src={rightToLeftArrow}
            alt="Arrow"
            className="hidden sm:block w-52 absolute -top-16 left-0 -scale-x-100 "
          />
        </section>

        <section className="relative px-5 md:px-24 2xl:px-32 py-16 flex flex-col md:flex-row justify-between items-center border-b-4 border-t-4 border-black bg-[#FF485C]">
          <h3 className="text-2xl lg:text-3xl 2xl:text-4xl font-gtAmericaBold md:font-kineticaBold text-white w-full md:w-1/2 leading-relaxed tracking-normal md:tracking-tight text-center md:text-left">
            Returns won't be a hassle anymore.
            <br className="hidden md:block" /> Enabling{" "}
            <br className="block  md:hidden" />
            <span className="text-3xl lg:text-3xl 2xl:text-4xl">
              Zero Pay Checkout.
            </span>
          </h3>
          <img
            src={partnerWithUsIllustration}
            alt=""
            className="w-64 xl:w-96 mt-6 md:mt-0 cursor-pointer"
            onClick={() => {
              navigate("/get-in-touch");
            }}
          />
          <img
            src={star}
            alt=""
            className="absolute -bottom-[2.91rem] left-5 hidden md:block"
          />
        </section>
      </main>
      <Footer />
    </>
  );
}
import React from "react";
import brandCardBgNormal from "../../images/brandCardBgNormal.png";
import brandCardBgHover from "../../images/brandCardBgHover.png";

type brandCard = {
    image: string,
    name: string,
};

export default function BrandCardForHome({ image, name }: brandCard) {
    return (
        <div className="relative m-auto group flex justify-center items-end w-fit transition-all ease-in-out h-auto ~sm:h-64  xl:h-80">
            <img
                src={image}
                alt={name}
                className="absolute top-[50%] bottom-0 w-11/12 "
            />
            <img
                src={brandCardBgNormal}
                alt="brand Card Bg"
                className="w-44 block group-hover:hidden transition-all ease-in-out "
            />
            <img
                src={brandCardBgHover}
                alt="brand Card Bg"
                className="w-48 hidden group-hover:block transition-all ease-in-out "
            />
        </div>
    );
}